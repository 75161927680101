import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import { Fragment } from 'react'

import { Typography } from 'common/widgets'
import AddOnCard from '../widgets/AddOnCard'
import CardWrapper from '../widgets/AddonCardWrapper'
import classNames from '../styles.module.scss'
import { useTranslations } from 'common/language'


AddOnsView.propTypes = {
  addOns: PropTypes.array.isRequired,
}


export default function AddOnsView({ addOns, ...rest }) {
  const { gettext } = useTranslations()
  return (
    <Fragment>
      {
        !isEmpty(addOns) && (
          <div className={classNames.serviceList}>
            <div className={classNames.serviceTitleWrapper}>
              <Typography className={classNames.serviceTitle} variant="h3-title" transform="uppercase">{gettext('Add-ons')}</Typography>
            </div>
            {addOns.map((service) => {
              const additionalData = {
                'private_lesson': {
                  initialValues: {
                    duration: get(service, 'durations[0].duration'),
                  },
                  defaultOptions: get(service, 'durations', []),
                },
                'work_program': {
                  initialValues: {
                    duration: get(service, 'durations[0].duration'),
                  },
                  defaultOptions: get(service, 'durations', []),
                },
                'leisure_program': {
                  initialValues: {
                    option_id: get(service, 'options[0].option.id'),
                  },
                  defaultOptions: get(service, 'options', []),
                },
              }

              return (
                <CardWrapper
                  kind={service.kind}
                  id={service.id}
                  key={service.id}
                  data={service.static_content}
                  title={service.name}
                  initialValues={additionalData[service.kind]?.initialValues}
                  defaultDenialMessage={service.denial_message}
                  {...rest}
                >
                  {(props) => (
                    <AddOnCard
                      {...props}
                      data={service.static_content}
                      kind={service.kind}
                      defaultOptions={additionalData[service.kind]?.defaultOptions}
                    />
                  )}
                </CardWrapper>
              )
            })}
          </div>
        )
      }
    </Fragment>
  )
}
