import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useMemo, useCallback, useEffect, useState } from 'react'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import orderBy from 'lodash/orderBy'
import omit from 'lodash/omit'
import cx from 'classnames'
import { useForm, useFormState } from 'react-final-form'

import { SelectField } from 'common/forms'
import classNames from './styles.module.scss'
import { Typography } from 'common/widgets'
import { useTranslations } from 'common/language'
import SelectInput from 'common/forms/inputs/SelectInput'
import BorderFieldLayout from 'common/forms/BorderFieldLayout'
import disableOptions from '../utils/disableOptions'
import changeDateFormat from 'common/utils/changeDateFormat'


const formatCombinations = (data = []) => {
  return Object.values(data.reduce((res, item) => {
    res[item.name] = res[item.name]?.disabled === false ? res[item.name] : item
    return res
  }, {}))
}

TransferCard.propTypes = {
  data: PropTypes.object.isRequired,
  priceBlock: PropTypes.node.isRequired,
  inShoppingCart: PropTypes.bool.isRequired,
  setPrice: PropTypes.func.isRequired,
  fieldData: PropTypes.object,
  defaultCombinations: PropTypes.array,
}

TransferCard.defaultProps = {
  fieldData: {},
  defaultCombinations: [],
}

export default function TransferCard({ fieldData, priceBlock, inShoppingCart, defaultCombinations, setPrice }) {
  const { gettext } = useTranslations()
  const [placeValue, changePlace] = useState(null)
  const { values } = useFormState()
  const form = useForm()

  let places = useMemo(_ => uniqBy(get(fieldData, 'combinations', defaultCombinations), 'combination.pickup_location.id').map(i => ({ name: i?.combination?.pickup_location?.name, id: i?.combination?.pickup_location?.id })), [fieldData.combinations])
  places = orderBy(places, ['name'])
  const getCombinations = useCallback(placeValue => formatCombinations(get(fieldData, 'combinations', defaultCombinations).map(i => ({ name: i?.combination?.transfer_type, id: i?.combination?.id, kind: i?.combination?.transfer_type_kind, disabled: i?.combination?.pickup_location?.id !== placeValue }))), [fieldData.combinations])
  const combinations = getCombinations(placeValue)
  const selectedCombination = useMemo(() => get(fieldData, 'combinations', defaultCombinations).find(i => i.combination.id === values.combination_id), [fieldData.combinations, values.combination_id])

  useEffect(() => {
    if(!fieldData.arrival_date) {
      setPrice({
        minPriceInCurrency: get(selectedCombination, 'price.reference') ? omit(get(selectedCombination, 'price'), ['reference']) : {},
        minPriceInChf: get(selectedCombination, 'price.reference') ? get(selectedCombination, 'price.reference') : omit(get(selectedCombination, 'price'), ['reference']),
      })
    } else {
      setPrice({
        priceInCurrency: get(selectedCombination, 'price.reference') ? get(selectedCombination, 'price') : {},
        priceInChf: get(selectedCombination, 'price.reference') ? get(selectedCombination, 'price.reference') : omit(get(selectedCombination, 'price'), ['reference']),
      })
    }
  }, [selectedCombination, fieldData.arrival_date])

  useEffect(() => {
    if(values.combination_id) {
      const currentCombination = get(fieldData, 'combinations', defaultCombinations).find(i => i?.combination?.id === values.combination_id)
      if(currentCombination) {
        changePlace(currentCombination?.combination?.pickup_location?.id)
      }
    }
  }, [values.combination_id])

  const handlePlaceChange = useCallback(value => {
    const combinations = getCombinations(value).filter(i => !i.disabled)
    const currentCombination = combinations.find(i => i.kind === 'arrival_departure')
    || combinations.find(i => i.kind === 'arrival') || combinations[0]
    changePlace(value)
    if(currentCombination) {
      return form.change('', {
        combination_id: currentCombination.id,
      })
    }
    return form.change('', {
      combination_id: null,
    })
  }, [changePlace, values, form.change, getCombinations])

  return (
    <div className={classNames.accommodationWrapper}>
      <Row type="flex" align="flex-start" justify="space-between">
        <Col flex="auto">
          <Row type="flex" align="flex-start" className={cx(classNames.typeField, classNames.transferTypeField, classNames.fieldWithMargin)}>
            <Col>
              <FontAwesomeIcon icon="plane" className={classNames.transferPlane} />
              <FontAwesomeIcon icon="train" />
            </Col>
            <Col flex="auto">
              <BorderFieldLayout
                inputComponent={SelectInput}
                options={places}
                valueKey="id"
                labelKey="name"
                value={placeValue}
                onChange={handlePlaceChange}
                disabled={inShoppingCart}
                withoutError
              />
            </Col>
          </Row>
          <Row type="flex" align="flex-start" className={cx(classNames.typeField, classNames.transferTypeField)}>
            <Col>
              <FontAwesomeIcon icon="exchange-alt" />
            </Col>
            <Col flex="auto">
              <SelectField
                name="combination_id"
                disabled={!combinations.length || inShoppingCart}
                options={combinations}
                labelKey="name"
                valueKey="id"
                isOptionDisabled={disableOptions}
                withoutError
              />
            </Col>
          </Row>
        </Col>
        <Col className={classNames.cardPrice}>
          {priceBlock}
        </Col>
      </Row>
      <Row type="flex" gutter={[{ lg: 32, xs: 0 }, 0]}>
        <Col span={24} lg={8} className={classNames.labelField}>
          <span className={cx(classNames.fieldLabel, classNames.firstLabel)}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Arrival \n date')}
            </Typography>
          </span>
          <div className={cx(classNames.endDate, inShoppingCart && classNames.disabled)}>
            {selectedCombination && selectedCombination?.combination?.transfer_type_kind !== 'departure' && (
              changeDateFormat(fieldData.arrival_date)
            )}
          </div>
        </Col>
        <Col span={24} lg={9} className={classNames.labelField}>
          <span className={classNames.fieldLabel}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Departure \n date')}
            </Typography>
          </span>
          <div className={cx(classNames.endDate, inShoppingCart && classNames.disabled)}>
            {selectedCombination && selectedCombination?.combination?.transfer_type_kind !== 'arrival' && (
              changeDateFormat(fieldData.departure_date)
            )}
          </div>
        </Col>
      </Row>
    </div>
  )
}
