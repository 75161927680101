import pick from 'lodash/pick'
import get from 'lodash/get'
import omit from 'lodash/omit'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import API from 'api'
import CardWrapperView from './CardWrapperView'
import usePrevious from 'common/utils/usePrevious'
import makeOrderByKind from 'common/utils/makeOrderByKind'


AddonCardWrapperContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  cartInitialValues: PropTypes.object,
  currentCart: PropTypes.object,
  defaultPrice: PropTypes.object,
}

AddonCardWrapperContainer.defaultProps = {
  cartInitialValues: {},
  currentCart: {},
  defaultPrice: {},
}

export default function AddonCardWrapperContainer({ id, cartInitialValues, currentCart, isCartLoading, defaultPrice, ...props }) {
  const { locale, query } = useRouter()
  const [fieldData, setFieldData] = useState({})
  const [price, setPrice] = useState({
    priceInCurrency: {},
    priceInChf: {},
    minPriceInCurrency: defaultPrice?.reference ? (defaultPrice || {}) : {},
    minPriceInChf: defaultPrice?.reference ? defaultPrice?.reference : omit((defaultPrice || {}), ['reference']),
  })
  const [ isLoading, setLoading ] = useState(false)
  const form = useForm()

  const handleSetPrice = useCallback((data = {}) => {
    setPrice({
      ...price,
      ...data,
    })
  }, [price, setPrice])

  const handleRequest = useCallback((data) => {
    setLoading(true)
    return API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/transfer/${id}`,
      { ...data },
      { headers: { 'Accept-Language': locale } },
    )
      .then(resp => {
        setFieldData(resp)
        return resp
      })
      .finally(() => setLoading(false))
  }, [locale, id, setFieldData, handleSetPrice, currentCart, cartInitialValues])

  const prevCart = usePrevious(currentCart)

  useEffect(() => {
    if(prevCart?.accommodation_cards?.length !== currentCart?.accommodation_cards?.length && !cartInitialValues) {
      if(currentCart?.accommodation_cards?.length > 0) {
        handleRequest(pick({ ...currentCart }, ['course_cards', 'accommodation_cards', 'transfer_cards']))
          .then(resp => {
            form.initialize({
              combination_id: makeOrderByKind(get(resp, 'combinations', []), [ 'arrival_departure', 'arrival', 'departure' ], 'combination.transfer_type_kind')?.[0]?.combination?.id,
            })
          })
      } else {
        setFieldData()
        handleSetPrice({
          priceInCurrency: {},
          priceInChf: {},
        })
      }
    }
  }, [currentCart?.accommodation_cards?.length])

  useEffect(() => {
    if(currentCart?.accommodation_cards?.length > 0) {
      handleRequest(pick({ ...currentCart }, ['course_cards', 'accommodation_cards', 'transfer_cards']))
    }
  }, [])

  return (
    <CardWrapperView
      {...props}
      fieldData={fieldData}
      price={price}
      setPrice={handleSetPrice}
      inShoppingCart={Boolean(cartInitialValues)}
      isLoading={isLoading || isCartLoading}
    />
  )
}
