import { Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { notification } from 'antd'

import Courses from './parts/Courses'
import Accommodations from './parts/Accommodations'
import AddOns from './parts/AddOns'
import Transfers from './parts/Transfers'
import api, { QS } from 'api'
import { useTranslations } from 'common/language'
import { useRouter } from 'next/router'


ServicesView.propTypes = {
  cartDataSingle: PropTypes.object,
}

ServicesView.defaultProps = {
  cartDataSingle: {},
}

export default function ServicesView({ cartDataSingle }) {
  const { gettext } = useTranslations()
  const { query } = useRouter()
  useEffect(() => {
    const removeRequestInterceptor = api.interceptors.response.use({
      onError: function({ data, response }) {
        if(response.status === 400) {
          if(data?.non_field_errors?.some((error) => error?.toLowerCase()?.includes('code'))) {
            data.non_field_errors.forEach((error) => {
              notification.error({
                message: gettext('Error'),
                description: error,
                duration: 5,
              })
            })
          } else {
            notification.error({
              message: gettext('Error'),
              description: gettext('We assume that you have invalid data in your shopping cart. Please clear it and try again.'),
              duration: 5,
            })
          }
        }
        throw data
      },
    })
    return removeRequestInterceptor
  }, [])
  if(typeof location !== 'undefined' && QS.parseQueryParams(location.search).option !== query.option) {
    return null
  }
  return (
    <Fragment key={query.option}>
      <Courses cartDataSingle={cartDataSingle} />
      <AddOns cartDataSingle={cartDataSingle} />
      <Accommodations cartDataSingle={cartDataSingle} />
      <Transfers cartDataSingle={cartDataSingle} />
    </Fragment>
  )
}
