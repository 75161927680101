import { connect } from 'react-redux'
import { compose } from 'redux'

import { withRouter } from 'next/router'
import { Cart } from 'common/widgets'
import withCart from 'hocs/withCartHOC'


export default compose(
  withRouter,
  withCart,
  connect((_, props) => {
    const url = new URL(props.router.asPath, process.env.BACKEND_URL)
    if(url.hash === '#prices' && !url.searchParams.get('option')) {
      const option = Object.entries(props.cartData.data).find(([_, v]) => url.pathname.endsWith(v.school.slug))?.[0]
      if(option) {
        url.searchParams.set('option', option)
        props.router.replace(url.pathname + url.search + url.hash)
      }
    }
    const uuid = url.searchParams.get('option') || 'mock'
    return {
      uuid,
    }
  }),
)(Cart)
