import { Component } from 'react'
import { autobind } from 'core-decorators'

import { lg } from 'common/utils/breakpoints'
import { addFixedToBody, moveBackAfterClose } from 'common/utils/modalScroll'


export default function withSidebarHOC(ComposedComponent) {
  class withSidebarHOCContainer extends Component {
    state = {
      openSidebar: false,
    }

    @autobind
    toggleSidebar() {
      this.setState({ openSidebar: !this.state.openSidebar },
        _ => {
          if(this.state.openSidebar) {
            addFixedToBody()
          } else {
            moveBackAfterClose()
          }
        }
      )
    }

    @autobind
    onResizeWindow() {
      if(!(typeof window === 'undefined') && window.innerWidth > lg && this.state.openSidebar) {
        this.toggleSidebar()
      }
    }

    componentWillUnmount() {
      window.removeEventListener('resize', this.onResizeWindow)
      moveBackAfterClose()
    }

    render() {
      if(!(typeof window === 'undefined')) {
        window.addEventListener('resize', this.onResizeWindow)
      }
      return (
        <ComposedComponent
          {...this.props}
          {...this.state}
          toggleSidebar={this.toggleSidebar}
        />
      )
    }
  }

  return withSidebarHOCContainer
}
