import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import cx from 'classnames'
import { useCallback, useEffect, useState } from 'react'

import { Typography, Button } from 'common/widgets'
import CourseCard from '../widgets/CourseCard'
import CardWrapper from '../widgets/CourseCardWrapper'
import classNames from '../styles.module.scss'
import { useTranslations } from 'common/language'


CoursesView.propTypes = {
  filteredCourses: PropTypes.array.isRequired,
  setShowAdditional: PropTypes.func.isRequired,
  allSchoolCourses: PropTypes.array.isRequired,
  showAdditional: PropTypes.bool.isRequired,
}

export default function CoursesView({ filteredCourses, allSchoolCourses, showAdditional, setShowAdditional, ...rest }) {
  const { gettext } = useTranslations()
  const [ filteredCoursesList, setFilteredCoursesList ] = useState(null)
  const resetFinderFilters = useCallback(() => setShowAdditional(false), [setShowAdditional])
  useEffect(() => {
    if(!filteredCoursesList) {
      setFilteredCoursesList(filteredCourses)
    }
  }, [filteredCourses, setFilteredCoursesList, filteredCoursesList])
  return (
    <div className={classNames.courseListWrapper}>
      {
        !isEmpty(filteredCoursesList) && (
          <div className={classNames.serviceList}>
            <div className={classNames.serviceTitleWrapper}>
              <Typography className={classNames.serviceTitle} variant="h3-title" transform="uppercase">{gettext('Courses')}</Typography>
            </div>
            {allSchoolCourses.map((service) => (
              <div key={service.id} className={cx(showAdditional && !filteredCoursesList.includes(service.id) && classNames.hidden)}>
                <CardWrapper
                  id={service.id}
                  data={service.static_content}
                  kind="course"
                  title={service.name}
                  promotions={service?.promotions}
                  initialValues={{ intensity_id: service?.intensities[0].intensity?.id }}
                  defaultPrice={service.min_price}
                  {...rest}
                >
                  {(props) => (
                    <CourseCard
                      {...props}
                      data={service.static_content}
                      intensities={service.intensities}
                    />
                  )}
                </CardWrapper>
              </div>
            ))}
          </div>
        )
      }
      {showAdditional && (
        <Button
          type="link"
          className={classNames.showMore}
          classNameIcon={classNames.showMoreIcon}
          icon="caret-down"
          onClick={resetFinderFilters}
        >
          {gettext('Show additional courses')}
        </Button>
      )}
    </div>
  )
}
