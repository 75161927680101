import { connect } from 'react-redux'
import get from 'lodash/get'

import MapView from './MapView'
import marker from '@img/marker.svg'
import markerSelected from '@img/markerSelected.svg'


export default connect((state, { additionalLocations = [] }) => ({
  locations: [...additionalLocations, {
    ...get(state, 'schoolNewServices.data.static_content.location', {}),
    rating: get(state, 'schoolNewServices.data.static_content.rating.overall'),
    name: get(state, 'schoolNewServices.data.name'),
  }],
  infoWindowContent: '<h2><%= name %></h2><h3><%= rating %> / 5</h3>',
  markerIcon: marker,
  markerIconSelected: markerSelected,
  openAllMarkers: true,
}))(MapView)
