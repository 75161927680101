import { Form } from 'react-final-form'
import pick from 'lodash/pick'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'

import { setCartExpiredDate } from 'common/utils/cartDate'
import useCart from 'hooks/useCart'
import API from 'api'
import AccommodationCardWrapperContainer from './AccommodationCardWrapperContainer'


AccommodationCardWrapper.propTypes = {
  initialValues: PropTypes.object,
  id: PropTypes.string.isRequired,
}

AccommodationCardWrapper.defaultProps = {
  initialValues: {},
}

export default function AccommodationCardWrapper({ initialValues, id, ...props }) {
  const { locale, query } = useRouter()
  const cartData = useCart()

  const currentCart = useMemo(() => cartData.data[query.option], [cartData.data, query.option])
  const cartInitialValues = useMemo(() => {
    const item = currentCart && currentCart.accommodation_cards.find(i => i.accommodation_id === id)
    return item ? pick({ ...item }, ['accommodation_id', 'combination_id', 'arrival_date', 'duration', 'promotions']) : null
  }, [currentCart, id])

  const putToCartHandler = useCallback((data, _) => {
    cartData.setCartLoading(true)

    let requestData = {
      ...(currentCart || {}),
      accommodation_cards: [
        ...(currentCart?.accommodation_cards || []),
        { ...data, accommodation_id: id },
      ],
    }
    if(cartInitialValues) {
      setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
      requestData = {
        ...cartData.data[query.option],
        accommodation_cards: [],
        transfer_cards: [],
      }
    }
    API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/options`,
      requestData,
      { headers: { 'Accept-Language': locale } }
    )
      .then(resp => {
        setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
        return cartData.setData({
          ...cartData.data,
          [query.option]: {
            ...cartData.data[query.option],
            ...resp,
          },
        })
      })
      .finally(() => cartData.setCartLoading(false))
  }, [currentCart, query.option, cartData.data, cartData.setCartLoading, id])

  return (
    <Form
      onSubmit={putToCartHandler}
    >
      {(formProps) => (
        <AccommodationCardWrapperContainer
          {...props}
          {...formProps}
          id={id}
          cartInitialValues={cartInitialValues}
          initialValues={initialValues}
          currentCart={currentCart}
          isCartLoading={cartData.isCartLoading}
        />
      )}
    </Form>
  )
}
