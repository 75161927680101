import { Form } from 'react-final-form'
import PropTypes from 'prop-types'
import { useCallback, useMemo } from 'react'
import { useRouter } from 'next/router'
import omit from 'lodash/omit'
import get from 'lodash/get'

import { setCartExpiredDate } from 'common/utils/cartDate'
import useCart from 'hooks/useCart'
import API from 'api'
import AddonCardWrapperContainer from './AddonCardWrapperContainer'


AddonCardWrapper.propTypes = {
  initialValues: PropTypes.object,
  id: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
}

AddonCardWrapper.defaultProps = {
  initialValues: {},
}

export default function AddonCardWrapper({ initialValues, id, kind, ...props }) {
  const { locale, query } = useRouter()
  const cartData = useCart()

  const currentCart = useMemo(() => cartData.data[query.option], [cartData.data, query.option])
  const cartInitialValues = useMemo(() => {
    const item = currentCart && currentCart[`${kind}_cards`].find(i => i[`${kind}_id`] === id)
    return item || null
  }, [currentCart, id, kind])

  const putToCartHandler = useCallback((data) => {
    cartData.setCartLoading(true)

    const cart = omit((currentCart || {}), ['shopping_cart'])
    let requestData = {
      ...cart,
      [`${kind}_cards`]: [
        ...(cart?.[`${kind}_cards`] || []),
        { ...data, [`${kind}_id`]: id },
      ],
    }
    if(cartInitialValues) {
      setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
      requestData = {
        ...cart,
        [`${kind}_cards`]: get(cart, `${kind}_cards`, []).filter(i => i[`${kind}_id`] !== data[`${kind}_id`]),
      }
    }
    API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/options`,
      requestData,
      { headers: { 'Accept-Language': locale } }
    )
      .then(resp => {
        setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
        return cartData.setData({
          ...cartData.data,
          [query.option]: {
            ...cartData.data[query.option],
            ...resp,
          },
        })
      })
      .finally(() => cartData.setCartLoading(false))
  }, [currentCart, query.option, cartData.setCartLoading, cartData.data, kind, id])

  return (
    <Form
      onSubmit={putToCartHandler}
    >
      {(formProps) => (
        <AddonCardWrapperContainer
          {...props}
          {...formProps}
          id={id}
          cartInitialValues={cartInitialValues}
          initialValues={initialValues}
          currentCart={currentCart}
          isCartLoading={cartData.isCartLoading}
          kind={kind}
        />
      )}
    </Form>
  )
}
