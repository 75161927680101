import { Component } from 'react'
import { autobind } from 'core-decorators'
import isEmpty from 'lodash/isEmpty'
import { ResourceType } from '@ds-frontend/resource'
import PropTypes from 'prop-types'


import SchoolPageView from './SchoolPageView'
import { lg } from 'common/utils/breakpoints'
import { Loading } from 'common/widgets'
import classNames from './styles.module.scss'
import { QS } from 'api'


const propTypes = {
  schoolNewServices: ResourceType.isRequired,
  router: PropTypes.object.isRequired,
}
export default class SchoolPageContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      openCart: false,
    }
  }

  @autobind
  toggleCart() {
    this.setState({ openCart: !this.state.openCart })
  }

  @autobind
  onResizeWindow() {
    if(window.innerWidth > lg && this.state.openCart) {
      this.toggleCart()
    }
  }

  @autobind
  moveBack() {
    const queries = JSON.parse(localStorage.getItem('finderFilters'))
    const url = `/${this.props.router.locale}/school-finder`
    this.props.router.push(isEmpty(queries) ? url : `${url}?${QS.buildQueryParams(queries)}`)
  }

  componentDidMount() {
    this.props.router.prefetch(`/${this.props.router.locale}/school-finder`)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResizeWindow)
    this.props.schoolNewServices.clear()
  }

  render() {
    const { schoolNewServices } = this.props
    if(isEmpty(schoolNewServices.data)) {
      return <div className={classNames.loadingWrapper}><Loading isLoading fontSize={48} /></div>
    }
    if(typeof window !== 'undefined') {
      window.addEventListener('resize', this.onResizeWindow)
    }
    return (
      <SchoolPageView
        {...this.props}
        {...this.state}
        toggleCart={this.toggleCart}
        moveBack={this.moveBack}
      />
    )
  }
}

SchoolPageContainer.propTypes = propTypes
