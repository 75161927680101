import get from 'lodash/get'


export default function orderCourseAge(item, key = 'name') {
  const value = get(item, key) || ''
  const clearValue = value.replace(/\D+/g, '')
  if(value.search('<') >= 0 || value.search('minus') >= 0) {
    return '-' + clearValue
  }
  if(value.search('>') >= 0 || value.search(/\+/g) >= 0 || value.search('plus') >= 0) {
    return parseInt(clearValue + '000', 10)
  }
  return parseInt(clearValue, 10)
}
