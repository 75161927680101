import { connect } from 'react-redux'
import get from 'lodash/get'

import { HeroImageWithTemplate } from 'common/widgets/blocks'


export default connect((state, props) => {
  const seoContext = {
    country_name: get(state, 'schoolNewServices.data.static_content.country.name'),
    destination_name: get(state, 'schoolNewServices.data.static_content.destination.name'),
    school_name: get(state, 'schoolNewServices.data.name'),
  }
  const image = get(state, 'schoolNewServices.data.static_content.media_images[0]')
  image.data.alttext = get(image, `data.alttext_${props.languageCode}`)
  image.data.name = get(image, `data.name_${props.languageCode}`)
  return {
    data: {
      image: image,
    },
    seoContext,
    pageType: 'public_site.SchoolPage',
  }
})(HeroImageWithTemplate)
