import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import cx from 'classnames'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'
import formatPrice from 'common/utils/formatPrice'


CardPrice.propTypes = {
  minPriceInCurrency: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  priceInCurrency: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  minPriceInChf: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  priceInChf: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  pricePerLesson: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  pricePerWeek: PropTypes.shape({
    currency: PropTypes.string,
    value: PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
    ]),
  }),
  calculatedPromotion: PropTypes.shape({
    id: PropTypes.string,
    duration: PropTypes.number,
    price_with_promotion: PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      reference: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    }),
    price_without_promotion: PropTypes.shape({
      currency: PropTypes.string,
      value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      reference: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
    }),
    promotion: PropTypes.shape({
      id: PropTypes.string,
      description: PropTypes.string,
      display_promo_tag: PropTypes.bool,
      display_slash_in_web_service_card: PropTypes.bool,
      display_price_saving_in_web_shopping_cart: PropTypes.bool }),
  }),
}

CardPrice.defaultProps = {
  minPriceInCurrency: {},
  priceInCurrency: {},
  minPriceInChf: {},
  priceInChf: {},
  pricePerLesson: {},
  pricePerWeek: {},
  calculatedPromotion: null,
}

export default function CardPrice({ minPriceInCurrency, priceInChf, minPriceInChf, priceInCurrency, pricePerLesson, pricePerWeek, calculatedPromotion }) {
  const currencyPrice = useMemo(_ => {
    if(isEmpty(priceInCurrency)) {
      return { ...minPriceInCurrency, min: true }
    }
    if(calculatedPromotion) {
      return get(calculatedPromotion, 'price_with_promotion')
    }
    return priceInCurrency
  }, [minPriceInCurrency, priceInCurrency, calculatedPromotion])
  const chfPrice = useMemo(_ => {
    if(isEmpty(priceInChf)) {
      return { ...minPriceInChf, min: true }
    }
    if(calculatedPromotion) {
      return get(calculatedPromotion, 'price_with_promotion.reference') || get(calculatedPromotion, 'price_with_promotion')
    }
    return priceInChf
  }, [minPriceInChf, priceInChf, calculatedPromotion])
  const chfNoPromotion = useMemo(_ => get(calculatedPromotion, 'price_without_promotion.reference') || get(calculatedPromotion, 'price_without_promotion'), [calculatedPromotion])
  const displaySlashPrice = useMemo(_ => get(calculatedPromotion, 'promotion.display_slash_in_web_service_card', false), [calculatedPromotion])
  const { gettext } = useTranslations()
  return (
    <div className={classNames.priceContent}>
      <div>
        {currencyPrice?.value !== undefined && currencyPrice?.currency !== 'CHF' && (
          <Typography variant="small">
            {currencyPrice.min && (
              gettext('From ')
            )}
            {currencyPrice.currency} {formatPrice(currencyPrice.value)}
          </Typography>
        )}
      </div>
      <div className={classNames.slashPriceContainer}>
        {chfPrice?.value !== undefined && (
          <Typography variant="h4" className={classNames.priceText} color={displaySlashPrice ? 'pink' : undefined}>
            {chfPrice.min && (
              <Typography tag="span" variant="small">
                {gettext('From ')}
              </Typography>
            )}
            {interpolate(gettext('CHF %s'), [formatPrice(chfPrice.value)])}
          </Typography>
        )}
        {displaySlashPrice && (
          <Typography variant="h4" className={cx(classNames.priceText, classNames.slashPrice)}>
            {chfNoPromotion?.currency} {formatPrice(chfNoPromotion?.value)}
          </Typography>
        )}
      </div>
      {pricePerLesson?.value !== undefined && (
        <Typography variant="small" className={classNames.pricePerLesson}>
          {pricePerLesson.currency} {formatPrice(pricePerLesson.value)} / {gettext('Lesson')}
        </Typography>
      )}
      {pricePerWeek?.value !== undefined && !calculatedPromotion && (
        <Typography variant="small" className={classNames.pricePerLesson}>
          {pricePerWeek.currency} {formatPrice(pricePerWeek.value)} / {gettext('Week')}
        </Typography>
      )}
      {calculatedPromotion && (
        <Typography variant="small" className={classNames.pricePerLesson}>
          {chfPrice.currency} {formatPrice(chfPrice.value / calculatedPromotion.duration)} / {gettext('Week')}
        </Typography>
      )}
      <div />
    </div>
  )
}
