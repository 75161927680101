import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { compose } from 'redux'
import { connect } from 'react-redux'
import withCart from 'hocs/withCartHOC'
import { withRouter } from 'next/router'


import ServicesContainer from './ServicesContainer'
import { checkCartExpiredDate } from 'common/utils/cartDate'
import { QS } from 'api'


export default compose(
  withRouter,
  withCart,
  connect((state, props) => {
    const uuid = get(state, 'schoolNewServices.data.id', '')
    let optionUUID
    if(typeof location !== 'undefined') {
      optionUUID = QS.parseQueryParams(location.search).option
    }
    let cartDataSingle = optionUUID && checkCartExpiredDate() ? get(props, `cartData.data.${optionUUID}`, {}) : {}
    if(!isEmpty(cartDataSingle)) {
      cartDataSingle = cartDataSingle.school.uuid === uuid ? cartDataSingle : {}
    }
    return {
      uuid,
      cartDataSingle,
    }
  }),
)(ServicesContainer)
