import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import cx from 'classnames'

import classNames from './styles.module.scss'
import Image from 'next/image'
import { Typography, Info } from 'common/widgets'
import TooltipDescription from './TooltipDescription'


const tooltipOffset = { offset: ['30%'] }

SimpleCardView.propTypes = {
  imgSrc: PropTypes.shape({ src: PropTypes.string, height: PropTypes.number, width: PropTypes.number, blurDataURL: PropTypes.string }).isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
  description: PropTypes.string,
  linkText: PropTypes.string,
  linkUrl: PropTypes.string,
  imgWrapperClassName: PropTypes.string,
}

SimpleCardView.defaultProps = {
  tooltip: '',
  description: '',
  linkText: '',
  linkUrl: '',
  imgWrapperClassName: undefined,
}

export default function SimpleCardView({ imgSrc, title, tooltip, description, linkText, linkUrl, imgWrapperClassName }) {
  return (
    <Row className={classNames.cardWrapper}>
      <Col span={24} lg={7} className={cx(classNames.imgWrapper, imgWrapperClassName)}>
        <Image
          objectFit="contain"
          layout="fill"
          alt="Airbnb Logo"
          placeholder="blur"
          src={imgSrc}
        />
      </Col>
      <Col span={24} lg={17} className={classNames.cardBody}>
        <div className={classNames.cardName}>
          <Typography
            className={cx(classNames.text, classNames.cardTitleText)}
            variant="h4-card"
          >
            <span>
              {title}
              {tooltip && (
                <Info
                  title={<TooltipDescription tooltip={{ category_description: tooltip }} />}
                  align={tooltipOffset}
                  overlayClassName={classNames.cardTooltip}
                />
              )}
            </span>
          </Typography>
        </div>
        {description && (
          <Typography
            variant="small"
            className={classNames.cardDescription}
          >
            {description}
          </Typography>
        )}
        {linkText && (
          <div className={classNames.linkBlock}>
            <a
              href={linkUrl}
              target="_blank"
              rel="noreferrer nofollow"
            >
              {linkText}
            </a>
          </div>
        )}
      </Col>
    </Row>
  )
}
