import { connect } from 'react-redux'
import get from 'lodash/get'
import { compose } from 'redux'
import { connectResources } from '@ds-frontend/resource'

import { withRouter } from 'next/router'
import SchoolPageContainer from './SchoolPageContainer'
import withSidebarHOC from 'hocs/withSidebarHOC'


export default compose(
  withRouter,
  connect((state, props) => ({
    schoolNew: get(state, 'schoolNewServices.data', {}),
    id: get(props.match, 'params.id'),
    country: get(props.match, 'params.country'),
    destination: get(props.match, 'params.destination'),
    seoTemplates: get(state, 'cmsInfo.data.seo_templates', {}),
  })),
  withSidebarHOC,
  connectResources('schoolNewServices')
)(SchoolPageContainer)
