import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useField, useForm, useFormState } from 'react-final-form'
import { useMemo, useEffect, useCallback, useState } from 'react'
import cx from 'classnames'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import sortBy from 'lodash/sortBy'
import moment from 'moment'
import omit from 'lodash/omit'

import DateInput from 'common/forms/inputs/DateInput'
import SelectInput from 'common/forms/inputs/SelectInput'
import classNames from './styles.module.scss'
import { Typography } from 'common/widgets'
import prepareOptionsToDuration from '../utils/prepareOptionsToDuration'
import { useTranslations } from 'common/language'
import BorderFieldLayout from 'common/forms/BorderFieldLayout'
import disableOptions from '../utils/disableOptions'
import changeDateFormat from 'common/utils/changeDateFormat'
import orderCourseAge from 'common/utils/orderCourseAge'
import getCardPricePromotion from 'common/utils/getCardPricePromotion'


const formatCombinations = (data = []) => {
  return Object.values(data.reduce((res, item) => {
    res[item.name] = res[item.name]?.disabled === false ? res[item.name] : item
    return res
  }, {}))
}

const buildRoomName = (data) => {
  let name = data?.room_type
  if(data?.age_restriction) {
    name = name + `, ${data.age_restriction}`
  }
  if(data?.bathroom_type) {
    name = name + `, ${data.bathroom_type}`
  }
  return name
}

AccommodationCard.propTypes = {
  data: PropTypes.object.isRequired,
  setPrice: PropTypes.func.isRequired,
  priceBlock: PropTypes.node.isRequired,
  inShoppingCart: PropTypes.bool.isRequired,
  fieldData: PropTypes.object,
  cart: PropTypes.object,
  defaultCombinations: PropTypes.array,
}

AccommodationCard.defaultProps = {
  fieldData: {
    available_arrival_dates: [],
  },
  cart: {},
  defaultCombinations: [],
}

export default function AccommodationCard({ setPrice, fieldData, priceBlock, cart, inShoppingCart, defaultCombinations }) {
  const { gettext } = useTranslations()
  const [roomValue, changeRoom] = useState(null)
  const form = useForm()
  const { values } = useFormState()
  useField('arrival_date')
  useField('combination_id')

  const isAvailable = Boolean(get(cart, 'course_cards.length', 0))
  const rooms = useMemo(_ => uniqBy(sortBy(get(fieldData, 'combinations', defaultCombinations), ['combination.room_type', item => orderCourseAge(item, 'combination.age_restriction'), 'combination.bathroom_type']).map(i => ({ label: buildRoomName(i?.combination) })), 'label'), [fieldData.combinations])
  const getCombinations = useCallback(
    roomValue =>
      sortBy(
        formatCombinations(
          get(fieldData, 'combinations', defaultCombinations)
            .map(i => ({ name: i?.combination?.meal_type, id: i?.combination?.id, min_price: i.min_price, disabled: buildRoomName(i?.combination) !== roomValue }))
        ), 'min_price.value'), [fieldData.combinations])
  const combinations = getCombinations(roomValue)
  const availableDates = useMemo(_ => get(fieldData, 'combinations', defaultCombinations).find(i => i?.combination?.id === values.combination_id), [fieldData.combinations, values.combination_id])
  const arrivalDates = get(availableDates, 'arrival_dates', []).map(i => i.arrival_date)
  const getDurations = useCallback(date => get(availableDates, 'arrival_dates', []).find(i => i.arrival_date === date), [availableDates])
  const durations = getDurations(values.arrival_date)
  const selectedDuration = useMemo(() => get(durations, 'durations', []).find(i => i?.duration?.quantity === values?.duration?.quantity), [durations, values.duration])
  const disabledDate = useCallback(date => !arrivalDates.includes(date.format('YYYY-MM-DD')), [arrivalDates])
  const selectedCardsLength = useMemo(_ => !isEmpty(cart) ? cart.accommodation_cards.length : 0, [cart])

  const calculatedPromotion = useMemo(() => getCardPricePromotion(selectedDuration?.promotions), [selectedDuration])
  if(calculatedPromotion) {
    calculatedPromotion.duration = get(selectedDuration, 'duration.quantity')
  }
  const handleDateChange = useCallback(value => {
    const lowerDurationPrice = getDurations(value)?.durations?.[0]?.price
    if(lowerDurationPrice) {
      setPrice({
        priceInCurrency: {},
        priceInChf: {},
        pricePerWeek: {},
        minPriceInChf: get(lowerDurationPrice, 'reference') ? get(lowerDurationPrice, 'reference') : omit(lowerDurationPrice, ['reference']),
        minPriceInCurrency: get(lowerDurationPrice, 'reference') ? lowerDurationPrice : {},
        calculatedPromotion: null,
      })
    }
    form.change('', {
      ...values,
      arrival_date: value,
      duration: null,
    })
  }, [form.change, values, getDurations, setPrice])

  const handleDurationChange = useCallback(duration => {
    const currentDuration = durations?.durations?.find(i => i.duration.quantity === duration.quantity)
    setPrice({
      priceInCurrency: get(currentDuration, 'price.reference') ? get(currentDuration, 'price') : {},
      priceInChf: get(currentDuration, 'price.reference') ? get(currentDuration, 'price.reference') : omit(get(selectedDuration, 'price'), ['reference']),
      pricePerWeek: currentDuration?.duration?.quantity ? {
        currency: get(currentDuration, 'price.reference.currency', get(currentDuration, 'price.currency')),
        value: get(currentDuration, 'price.reference.value', get(currentDuration, 'price.value')) / currentDuration.duration.quantity,
      } : {},
    })
    form.change('', {
      ...values,
      duration,
    })
  }, [form.change, values, durations])

  const handleCombinationChange = useCallback(value => {
    const currentCombination = get(fieldData, 'combinations', defaultCombinations).find(i => i?.combination?.id === value)
    if(isAvailable) {
      const defaultArrivalDate = currentCombination?.default_arrival_date
      const arrivalDates = get(currentCombination, 'arrival_dates', [])
      const firstArrivalDate = arrivalDates.length && (arrivalDates.find(i => i.arrival_date === defaultArrivalDate) || arrivalDates[0])
      const durations = firstArrivalDate?.durations
      const currentDuration = durations ? durations[durations.length - 1] : null
      setPrice({
        priceInCurrency: get(currentDuration, 'price.reference') ? get(currentDuration, 'price') : {},
        priceInChf: get(currentDuration, 'price.reference') ? get(currentDuration, 'price.reference') : omit(get(selectedDuration, 'price'), ['reference']),
        pricePerWeek: currentDuration?.duration?.quantity ? {
          currency: get(currentDuration, 'price.reference.currency', get(currentDuration, 'price.currency')),
          value: get(currentDuration, 'price.reference.value', get(currentDuration, 'price.value')) / currentDuration.duration.quantity,
        } : {},
      })
      return form.change('', {
        combination_id: value,
        arrival_date: firstArrivalDate?.arrival_date || null,
        duration: currentDuration?.duration || null,
      })
    }
    setPrice({
      priceInCurrency: {},
      priceInChf: {},
      minPriceInCurrency: get(currentCombination, 'min_price.reference') ? get(currentCombination, 'min_price') : {},
      minPriceInChf: get(currentCombination, 'min_price.reference') ? get(currentCombination, 'min_price.reference') : omit(get(selectedDuration, 'min_price'), ['reference']),
      calculatedPromotion: null,
    })
    return form.change('', {
      combination_id: value,
      arrival_date: null,
      duration: null,
    })
  }, [form.change, values, fieldData?.combinations, defaultCombinations])

  useEffect(() => {
    if(values.combination_id) {
      const currentCombination = get(fieldData, 'combinations', defaultCombinations).find(i => i?.combination?.id === values.combination_id)
      if(currentCombination) {
        changeRoom(buildRoomName(currentCombination?.combination))
      }
    }
  }, [values.combination_id])

  useEffect(() => {
    setPrice({
      calculatedPromotion: calculatedPromotion,
    })
  }, [calculatedPromotion?.id])

  const handleChangeRoom = useCallback(value => {
    changeRoom(value)
    const lowerCombinationPrice = getCombinations(value).filter(i => !i.disabled)?.[0]?.min_price
    if(lowerCombinationPrice) {
      setPrice({
        priceInCurrency: {},
        priceInChf: {},
        pricePerWeek: {},
        minPriceInChf: get(lowerCombinationPrice, 'reference') ? get(lowerCombinationPrice, 'reference') : omit(lowerCombinationPrice, ['reference']),
        minPriceInCurrency: get(lowerCombinationPrice, 'reference') ? lowerCombinationPrice : {},
        calculatedPromotion: null,
      })
    }
    form.change('', {
      combination_id: null,
      duration: null,
      arrival_date: null,
    })
  }, [changeRoom, form.change, setPrice, getCombinations])
  return (
    <div className={classNames.accommodationWrapper}>
      <Row type="flex" align="flex-start" justify="space-between">
        <Col flex="auto">
          <Row type="flex" align="flex-start" className={cx(classNames.typeField, classNames.fieldWithMargin)}>
            <Col className={classNames.accommodationCol}>
              <FontAwesomeIcon className={classNames.black} icon="bed" />
            </Col>
            <Col flex="auto">
              <BorderFieldLayout
                inputComponent={SelectInput}
                options={rooms}
                valueKey="label"
                labelKey="label"
                value={roomValue}
                onChange={handleChangeRoom}
                disabled={inShoppingCart || isEmpty(rooms)}
                withoutError
              />
            </Col>
          </Row>
          <Row type="flex" align="flex-start" className={classNames.typeField}>
            <Col className={classNames.accommodationCol}>
              <FontAwesomeIcon className={classNames.black} icon="utensils" />
            </Col>
            <Col flex="auto">
              <BorderFieldLayout
                inputComponent={SelectInput}
                disabled={!combinations.length || inShoppingCart}
                options={combinations}
                valueKey="id"
                labelKey="name"
                isOptionDisabled={disableOptions}
                value={values.combination_id}
                onChange={handleCombinationChange}
                withoutError
              />
            </Col>
          </Row>
        </Col>
        <Col className={classNames.cardPrice}>
          {priceBlock}
        </Col>
      </Row>
      <Row type="flex" justify="space-between" gutter={[{ lg: 15, xs: 0 }, 0]}>
        <Col span={24} lg={8} className={classNames.labelField}>
          <span className={cx(classNames.fieldLabel, classNames.firstLabel)}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Arrival \n date')}
            </Typography>
          </span>
          <BorderFieldLayout
            inputComponent={DateInput}
            disabledDate={disabledDate}
            disabled={isEmpty(arrivalDates) || !values.combination_id || inShoppingCart || !isAvailable}
            defaultPickerValue={arrivalDates[0] ? moment(arrivalDates[0], 'YYYY-MM-DD') : undefined}
            placeholder={fieldData.is_selectable ? gettext('Select') : ''}
            key={selectedCardsLength}
            onChange={handleDateChange}
            value={values.arrival_date}
            withoutError
          />
        </Col>
        <Col span={24} lg={8} className={classNames.labelField}>
          <span className={classNames.fieldLabel}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Duration')}
            </Typography>
          </span>
          <BorderFieldLayout
            inputComponent={SelectInput}
            onChange={handleDurationChange}
            value={values.duration}
            options={prepareOptionsToDuration(get(durations, 'durations', []))}
            disabled={!values.arrival_date || inShoppingCart || !isAvailable}
            labelKey="label"
            valueKey="duration"
            placeholder={!values.arrival_date ? '' : gettext('Select')}
            withoutError
          />
        </Col>
        <Col span={24} lg={8} className={classNames.labelField}>
          <span className={classNames.fieldLabel}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Departure \n date')}
            </Typography>
          </span>
          <div className={cx(classNames.endDate, inShoppingCart && classNames.disabled)}>
            {changeDateFormat(selectedDuration?.calculated_departure_date)}
          </div>
        </Col>
      </Row>
    </div>
  )
}
