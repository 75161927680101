import get from 'lodash/get'
import Script from 'next/script'
import API from 'api'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import globalRequest from 'common/utils/globalRequest'
import getCampaignBanner from 'common/utils/getCampaignBanner'

import SchoolPageComponent from 'components/schoolPage'
import AppLayout from 'layouts/AppLayout'
import GenericPage from 'components/genericPage'


export async function getStaticPaths({ locales }) {
  const requiredLocales = locales.filter(locale => !process.env.EXCLUDE_LOCALES.includes(locale))
  let cmsPaths = []
  let schoolPaths = []
  if(process.env.COLLECT_STATIC_FOR_CMS_PAGES) {
    const cmsRequests = requiredLocales.map(locale => API.get('api/v1/pages', {
      headers: { 'Accept-Language': locale },
      params: {
        limit: process.env.COLLECT_STATIC_FOR_CMS_PAGES_LIMIT,
      },
    }).catch(() => []))
    let cmsData = await Promise.all(cmsRequests).catch(() => [])
    cmsPaths = cmsData.reduce((sum, current, index) => {
      return [...sum, ...get(current, 'items', []).filter(i => i.slug.split('/')).map(i => ({
        params: {
          levels: i.slug.split('/'),
        },
        locale: requiredLocales[index],
      }))]
    }, [])
  }
  if(process.env.COLLECT_STATIC_FOR_SCHOOL_PAGES) {
    const schoolRequests = requiredLocales.map(locale => API.post('api/v1/search/maps', { size: 10000 }, { headers: { 'Accept-Language': locale } }))
    let schoolData = await Promise.all(schoolRequests).catch(() => [])
    schoolPaths = schoolData.reduce((sum, current, index) => {
      return [...sum, ...get(current, 'hits.hits', []).map(i => ({
        params: {
          levels: [
            get(i, '_source.country.slug'),
            get(i, '_source.destination.slug'),
            get(i, '_id'),
          ],
        },
        locale: requiredLocales[index],
      }))]
    }, [])
  }

  return { paths: [...cmsPaths, ...schoolPaths], fallback: 'blocking' }
}

export async function getStaticProps({ params, locale }) {
  let props = {
    initialReduxStore: {},
  }
  const pageData = await API.get(`api/v1/pages/${params.levels.join('/')}`, { headers: { 'Accept-Language': locale } }).catch(() => null)
  if(pageData?.action === 'redirect') {
    if(process.env.BUILD_PROCESS) {
      return {
        notFound: true,
        revalidate: 10,
      }
    } else {
      if(process.env.BUILD_PROCESS) {
        return {
          notFound: true,
          revalidate: 10,
        }
      } else {
        return {
          redirect: {
            destination: `/${locale}${pageData.slug}`,
            statusCode: pageData?.is_permanent ? 301 : 302,
          },
          revalidate: process.env.REVALIDATE_CMS_PAGE,
        }
      }
    }
  }
  if(pageData) {
    const contactForm = pageData.content.find(i => i.type === 'contact_form')?.value?.form
    if(contactForm) {
      contactForm.fields = await API.get('api/v1/crm/forms/contact-form', { headers: { 'Accept-Language': locale } })
    }
    const experienceMasonry = pageData.content.find(i => i.type === 'experience_masonry_grid')
    if(experienceMasonry) {
      props.initialReduxStore.getExperiences = await API.get('api/v1/feefo/experiences', { headers: { 'Accept-Language': locale }, params: { kind: experienceMasonry.value.kind, parent_id: experienceMasonry.value.parent_id, offset: 0, limit: 3 } })
        .then(res => ({ data: res }))
        .catch(() => null)
    }
    const productReviews = pageData.content.find(i => i.type === 'product_reviews_list')
    if(productReviews) {
      props.initialReduxStore.productReviews = await API.get('api/v1/feefo/product-reviews', { headers: { 'Accept-Language': locale }, params: { kind: pageData.reviews_parameters.kind, parent_id: pageData.reviews_parameters.parent_id, offset: 0, limit: 3 } })
        .then(res => ({ data: res }))
        .catch(() => null)
    }
    props = { ...props, pageData }
  }
  if(!pageData) {
    if(params.levels.length !== 3) {
      return { notFound: true, revalidate: process.env.REVALIDATE_CMS_PAGE }
    }
    const schoolDataNew = await API.get(`api/v2/public/schools/${params.levels[2]}`, { headers: { 'Accept-Language': locale, 'Content-Type': 'application/json' } }).catch(() => null)
    if(!schoolDataNew) {
      return { notFound: true, revalidate: process.env.REVALIDATE_SCHOOL_PAGE }
    }
    const initialExperiences = await API.get('api/v1/feefo/experiences', { headers: { 'Accept-Language': locale }, params: { kind: 'school', parent_id: schoolDataNew.id, offset: 0, limit: 3 } })
      .catch(() => null)
    const productReviews = await API.get('api/v1/feefo/product-reviews', { headers: { 'Accept-Language': locale }, params: { kind: 'school', parent_id: schoolDataNew.id, offset: 0, limit: 3 } })
      .catch(() => null)
    props = {
      initialReduxStore: {
        schoolNewServices: {
          data: schoolDataNew,
        },
        getExperiences: {
          data: initialExperiences,
        },
        productReviews: {
          data: productReviews,
        },
      },
    }
  }
  const defaultData = await globalRequest(locale)
  const campaignBanner = getCampaignBanner({ slug: params.levels.join('/'), homeSlug: get(defaultData, 'cmsInfo.data.home_page_slugs.' + locale), banners: get(defaultData, 'cmsInfo.data.campaign_banners') })
  return { props: { ...props, locale, initialReduxStore: { ...props.initialReduxStore, ...defaultData, ...campaignBanner } }, revalidate: props.pageData ? process.env.REVALIDATE_CMS_PAGE : process.env.REVALIDATE_SCHOOL_PAGE }
}

PageComponent.propTypes = {
  pageData: PropTypes.object,
  locale: PropTypes.string.isRequired,
}

PageComponent.defaultProps = {
  pageData: undefined,
}

export default function PageComponent({ pageData, locale }) {
  const isHeaderFooterEnabled = useMemo(() => ({
    isHeaderEnabled: get(pageData, 'display_header', true),
    isFooterEnabled: get(pageData, 'display_footer', true),
  }), [pageData])
  const isMapEnabled = !pageData || Boolean(pageData.map_locations) || ['kontakt', 'contact'].includes(pageData.slug)
  return (
    <AppLayout withCover {...isHeaderFooterEnabled} withoutCart={!pageData}>
      {isMapEnabled && <Script strategy="beforeInteractive" src="/scripts/initMap.js" />}
      {isMapEnabled && <Script src={`https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_KEY}&libraries=places&libraries=marker&language=${locale}&loading=async&callback=initMap`} />}
      {!pageData ? (
        <SchoolPageComponent />
      ) : (
        <GenericPage data={pageData} />
      )}
    </AppLayout>
  )
}
