import { connect } from 'react-redux'
import get from 'lodash/get'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { AlbumGalleryCard } from 'common/widgets/blocks'
import { useTranslations, interpolate } from 'common/language'


SchoolDescription.propTypes = {
  data: PropTypes.object.isRequired,
}

function SchoolDescription({ data, ...props }) {
  const { gettext } = useTranslations()
  const dataObject = useMemo(() => ({ ...data, heading: interpolate(gettext('Why %s'), [data.heading]) }), [data])
  return (
    <AlbumGalleryCard {...props} data={dataObject} />
  )
}

export default connect(state => ({
  data: {
    heading: get(state, 'schoolNewServices.data.name', ''),
    heading_level: '2',
    text: get(state, 'schoolNewServices.data.static_content.description', ''),
    images: get(state, 'schoolNewServices.data.static_content.media_images', []).slice(1),
  },
}))(SchoolDescription)
