import PropTypes from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import get from 'lodash/get'
import { Fragment } from 'react'

import { Typography } from 'common/widgets'
import TransferCard from '../widgets/TransferCard'
import CardWrapper from '../widgets/TransferCardWrapper'
import classNames from '../styles.module.scss'
import { useTranslations } from 'common/language'
import makeOrderByKind from 'common/utils/makeOrderByKind'


TransfersView.propTypes = {
  transfers: PropTypes.array.isRequired,
}


export default function TransfersView({ transfers, ...rest }) {
  const { gettext } = useTranslations()
  return (
    <Fragment>
      {
        !isEmpty(transfers) && (
          <div className={classNames.serviceList}>
            <div className={classNames.serviceTitleWrapper}>
              <Typography className={classNames.serviceTitle} variant="h3-title" transform="uppercase">{gettext('Transfer')}</Typography>
            </div>
            {transfers.map(service => (
              <CardWrapper
                kind="transfer"
                key={service.id}
                id={service.id}
                data={service.static_content}
                title={service.name}
                minPrice={service.min_price}
                initialValues={{ combination_id: makeOrderByKind(orderBy(get(service, 'combinations', []), ['combination.pickup_location.name']), [ 'arrival_departure', 'arrival', 'departure' ], 'combination.transfer_type_kind')?.[0]?.combination?.id }
                }
                defaultDenialMessage={service.denial_message}
                defaultPrice={service.min_price}
                {...rest}
              >
                {(props) => (
                  <TransferCard
                    {...props}
                    data={service.static_content}
                    defaultCombinations={service.combinations}
                  />
                )}
              </CardWrapper>
            ))}
          </div>
        )
      }
    </Fragment>
  )
}
