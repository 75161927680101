import get from 'lodash/get'
import omit from 'lodash/omit'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import API from 'api'
import CardWrapperView from './CardWrapperView'
import usePrevious from 'common/utils/usePrevious'
import getCardPricePromotion from 'common/utils/getCardPricePromotion'


CourseCardWrapperContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  cartInitialValues: PropTypes.object,
  initialValues: PropTypes.object,
  currentCart: PropTypes.object,
  values: PropTypes.object,
  defaultPrice: PropTypes.object,
  defaultIntensity: PropTypes.string,
}

CourseCardWrapperContainer.defaultProps = {
  cartInitialValues: {},
  initialValues: {},
  currentCart: {},
  values: {},
  defaultPrice: {},
  defaultIntensity: '',
}

export default function CourseCardWrapperContainer({ id, cartInitialValues, initialValues, currentCart, isCartLoading, defaultIntensity, values, defaultPrice, ...props }) {
  const { locale, query } = useRouter()
  const [fieldData, setFieldData] = useState({})
  const [price, setPrice] = useState({
    priceInCurrency: {},
    priceInChf: {},
    pricePerWeek: {},
    minPriceInCurrency: {},
    minPriceInChf: {},
    calculatedPromotion: null,
  })
  const [ isLoading, setLoading ] = useState(false)
  const form = useForm()

  const course = useMemo(_ => get(currentCart, 'course_cards', []).find(i => i.course_id === id), [currentCart?.course_cards, id])

  const appliedPromotionTag = useMemo(_ => get(course, 'promotions', []).find(i => i.promotion.display_promo_tag)?.promotion ?? null, [course])

  const handleSetPrice = useCallback((data = {}) => {
    setPrice(prevPrice => ({
      ...prevPrice,
      ...data,
    }))
  }, [])

  const handleRequest = useCallback((data = { course_cards: [] }, intensity) => {
    setLoading(true)
    return API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/course/${id}/${intensity || values.intensity_id || defaultIntensity}`,
      data,
      { headers: { 'Accept-Language': locale } },
    )
      .then(resp => {
        setFieldData(resp)
        if(!cartInitialValues) {
          handleSetPrice({
            priceInCurrency: {},
            priceInChf: {},
            pricePerWeek: {},
            minPriceInCurrency: get(resp, 'min_price.reference') ? get(resp, 'min_price') : {},
            minPriceInChf: get(resp, 'min_price.reference') ? get(resp, 'min_price.reference') : omit(get(resp, 'min_price'), ['reference']),
          })
        }
      })
      .finally(() => setLoading(false))
  }, [locale, id, setFieldData, handleSetPrice, currentCart?.course_cards, defaultIntensity, values.intensity_id, defaultPrice, cartInitialValues])

  const prevLength = usePrevious(currentCart?.course_cards?.length)
  const prevCart = usePrevious(currentCart)

  useEffect(() => {
    if(prevLength !== undefined && prevLength !== currentCart?.course_cards?.length && !cartInitialValues) {
      form.initialize(initialValues)
      handleRequest({
        course_cards: [
          ...(currentCart?.course_cards || []),
        ],
      }, defaultIntensity)
    }
  }, [currentCart?.course_cards?.length])
  useEffect(() => {
    if(cartInitialValues) {
      const durationCount = cartInitialValues?.duration?.quantity
      setPrice({
        priceInCurrency: get(cartInitialValues, 'price.reference') ? get(cartInitialValues, 'price') : {},
        priceInChf: get(cartInitialValues, 'price.reference') ? get(cartInitialValues, 'price.reference') : omit(get(cartInitialValues, 'price'), ['reference']),
        pricePerWeek: durationCount ? {
          currency: get(cartInitialValues, 'price.reference.currency', get(cartInitialValues, 'price.currency')),
          value: get(cartInitialValues, 'price.reference.value', get(cartInitialValues, 'price.value')) / durationCount,
        } : {},
        calculatedPromotion: getCardPricePromotion(cartInitialValues?.promotions),
      })
      handleRequest()
    } else {
      handleRequest({
        course_cards: [
          ...(currentCart?.course_cards || []),
        ],
      })
    }
  }, [])

  const prevIntensity = usePrevious(values.intensity_id)
  useEffect(() => {
    if(prevIntensity && prevIntensity !== values.intensity_id) {
      handleRequest({
        course_cards: [
          ...(currentCart?.course_cards || []),
        ],
      })
    }
  }, [values.intensity_id])

  useEffect(() => {
    const calculatedPromotion = getCardPricePromotion(course?.promotions)
    const prevPromotion = getCardPricePromotion(get(prevCart, 'course_cards', []).find(i => i.course_id === id)?.promotions)
    if(calculatedPromotion) {
      calculatedPromotion.duration = get(course, 'duration.quantity')
    }
    if(calculatedPromotion && calculatedPromotion?.promotion?.id !== prevPromotion?.promotion?.id) {
      const durationCount = course?.duration?.quantity
      handleSetPrice({
        priceInCurrency: get(course, 'price'),
        priceInChf: get(course, 'price.reference'),
        pricePerWeek: durationCount ? {
          currency: get(course, 'price.reference.currency'),
          value: get(course, 'price.reference.value') / durationCount,
        } : {},
        calculatedPromotion: calculatedPromotion,
      })
    } else if(prevPromotion && calculatedPromotion?.promotion?.id !== prevPromotion?.promotion?.id) {
      handleSetPrice({ calculatedPromotion: null })
    }
  }, [course?.promotions])

  return (
    <CardWrapperView
      {...props}
      fieldData={fieldData}
      price={price}
      appliedPromotionTag={appliedPromotionTag}
      setPrice={handleSetPrice}
      inShoppingCart={Boolean(cartInitialValues)}
      isLoading={isLoading || isCartLoading}
    />
  )
}
