import { useMemo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Tooltip } from 'antd'

import { useTranslations } from 'common/language'
import classNames from './styles.module.scss'


PromotionLabel.propTypes = {
  title: PropTypes.string,
}

PromotionLabel.defaultProps = {
  title: '',
}

export default function PromotionLabel({ title }) {
  const { gettext } = useTranslations()

  const alignProp = useMemo(() => ({
    // For more information visit https://github.com/yiminghe/dom-align
    points: ['bc', 'tc'],
    overflow: { adjustX: true, adjustY: true, alwaysByViewport: true },
  }), [])

  return (
    <Tooltip title={title} align={alignProp} overlayClassName={classNames.tooltip}>
      <div className={classNames.promotion}>
        <FontAwesomeIcon icon="fas fa-tag" />
        <span>
          {gettext('Promotion')}
        </span>
      </div>
    </Tooltip>
  )
}
