import { connect } from 'react-redux'
import get from 'lodash/get'
import { compose } from 'redux'
import { useEffect } from 'react'

import { withRouter } from 'next/router'
import CoursesView from './CoursesView'
import useLocalStorage from 'hooks/useLocalStorage'


const Courses = compose(
  withRouter,
  connect((state, props) => {
    const finderFilters = props.finderFilters
    const allSchoolCourses = get(state, 'schoolNewServices.data.courses', [])
    const filteredCourses = allSchoolCourses
      .filter(course => (
        (!finderFilters.languages || finderFilters.languages.includes(course.language?.id))
        && (!finderFilters.courseTypes || finderFilters.courseTypes.some(i => i === course.course_category?.id || i === course.course_type?.id))
        && (!finderFilters.courseAgeCategories || finderFilters.courseAgeCategories.includes(course.age_category?.id))
      )).map(item => item.id)
    return ({
      filteredCourses,
      allSchoolCourses,
      showAdditional: props.showAdditional ? allSchoolCourses.length > filteredCourses.length : props.showAdditional,
    })
  }),
)(CoursesView)

export default function CoursesComponent(props) {
  const [ finderFilters, setFinderFilters ] = useLocalStorage('finderFilters', {})
  const [ showAdditional, setShowAdditional ] = useLocalStorage('showAdditional', true)
  useEffect(() => {
    window.addEventListener('pagehide', () => {
      setFinderFilters({})
      window.localStorage.removeItem('showAdditional')
    }, { once: true })
  }, [])
  return (
    <Courses
      {...props}
      finderFilters={finderFilters}
      setFinderFilters={setFinderFilters}
      showAdditional={showAdditional}
      setShowAdditional={setShowAdditional}
    />
  )
}
