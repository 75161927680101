import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'


export default function prepareOptionsToDuration(data) {
  if(isEmpty(data)) {
    return []
  }
  return data.map(item => ({
    ...item,
    label: `${get(item, 'duration.quantity')} ${get(item, 'duration.unit_label', '')}`,
  }))
}
