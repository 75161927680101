import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import { useField } from 'react-final-form'
import { useMemo, useCallback, Fragment, useEffect } from 'react'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import moment from 'moment'
import cx from 'classnames'

import { SelectField } from 'common/forms'
import SelectInput from 'common/forms/inputs/SelectInput'
import DateInput from 'common/forms/inputs/DateInput'
import classNames from './styles.module.scss'
import { Typography, CardTextContent } from 'common/widgets'
import prepareOptionsToDuration from '../utils/prepareOptionsToDuration'
import { useTranslations } from 'common/language'
import changeDateFormat from 'common/utils/changeDateFormat'
import BorderFieldLayout from 'common/forms/BorderFieldLayout'
import getCardPricePromotion from 'common/utils/getCardPricePromotion'


CourseCard.propTypes = {
  data: PropTypes.object.isRequired,
  priceBlock: PropTypes.node.isRequired,
  setPrice: PropTypes.func.isRequired,
  fieldData: PropTypes.object,
  cart: PropTypes.object,
  inShoppingCart: PropTypes.bool,
  intensities: PropTypes.array,
}

CourseCard.defaultProps = {
  fieldData: {},
  cart: undefined,
  inShoppingCart: false,
  intensities: [],
}

export default function CourseCard({ data, fieldData, priceBlock, cart, inShoppingCart, setPrice, intensities }) {
  const { gettext } = useTranslations()
  const { input: startDateInput } = useField('start_date')
  const { input: durationInput } = useField('duration')
  const { input: intensityInput } = useField('intensity_id')
  const currentIntensity = get(fieldData, 'intensities[0]')
  const startDates = useMemo(_ =>
    get(currentIntensity, 'start_dates', [])
      .map(i => i.start_date)
      .filter(i => moment(i, 'YYYY-MM-DD').diff(moment().startOf('day'), 'days') > 2),
  [currentIntensity])
  const currentStartDate = get(currentIntensity, 'start_dates', []).find(i => i.start_date === startDateInput.value)
  const durationOptions = useMemo(_ => get(currentStartDate, 'durations', []), [currentStartDate])
  const currentDuration = useMemo(() => durationOptions.find(i => isEqual(i.duration, durationInput.value)), [durationOptions, durationInput.value])
  const calculatedPromotion = useMemo(() => {
    const cartPromotion = getCardPricePromotion(get(cart, 'course_cards', []).find(i => fieldData?.course_id === i.course_id)?.promotions)
    return !isEmpty(currentDuration?.promotions) ? getCardPricePromotion(currentDuration?.promotions) : cartPromotion
  }, [currentDuration, cart, fieldData?.course_id])
  if(calculatedPromotion) {
    calculatedPromotion.duration = get(currentDuration, 'duration.quantity')
  }
  const clearDurations = useCallback(() => durationInput.onChange(null), [durationInput.onChange])
  const selectedCardsLength = useMemo(_ => !isEmpty(cart) ? cart.course_cards.length : 0, [cart])
  const defaultPickerValue = useMemo(_ => startDates[0] ? moment(startDates[0], 'YYYY-MM-DD') : undefined, [startDates[0]])
  const disabledDate = useCallback(date => !startDates.includes(date.format('YYYY-MM-DD')), [startDates])
  const handleIntensityChange = useCallback(value => {
    startDateInput.onChange(null)
    durationInput.onChange(null)
    intensityInput.onChange(value)
  }, [intensityInput.onChange])
  const handleStartDateChange = useCallback(value => {
    durationInput.onChange(null)
    startDateInput.onChange(value)
  })

  useEffect(() => {
    const minDurationCount = get(currentDuration, 'duration.quantity')
    setPrice({
      priceInCurrency: get(currentDuration, 'price.reference') ? get(currentDuration, 'price') : {},
      priceInChf: get(currentDuration, 'price.reference') ? get(currentDuration, 'price.reference') : omit(get(currentDuration, 'price'), ['reference']),
      pricePerWeek: minDurationCount ? {
        currency: get(currentDuration, 'price.reference.currency', get(currentDuration, 'price.currency')),
        value: get(currentDuration, 'price.reference.value', get(currentDuration, 'price.value')) / minDurationCount,
      } : {},
      calculatedPromotion: calculatedPromotion,
    })
  }, [currentDuration?.price])

  return (
    <Fragment>
      <Row type="flex" align="flex-start" justify="space-between" className={classNames.contentRow}>
        <Col>
          <CardTextContent
            studentAge={{ min: data.min_student_age, avg: data.avg_student_age }}
            groupSize={{ avg: data.avg_students_count, max: data.max_students_count }}
            languageNiveau={{ max: data.language_niveau_max, min: data.language_niveau_min }}
          />
        </Col>
        <Col className={classNames.cardPrice}>
          {priceBlock}
        </Col>
      </Row>
      <div className={classNames.fieldBlock}>
        <div className={classNames.labelField}>
          <span className={classNames.typeField}>
            <i className="schedule" />
          </span>
          <BorderFieldLayout
            inputComponent={SelectInput}
            options={intensities}
            labelKey="intensity.name"
            valueKey="intensity.id"
            value={intensityInput.value}
            onChange={handleIntensityChange}
            withoutError
            disabled={inShoppingCart}
          />
        </div>
      </div>
      <Row className={classNames.bottomBar} type="flex" justify="space-between" gutter={[{ lg: 15, xs: 0 }, 0]}>
        <Col span={24} lg={8} className={classNames.labelField}>
          <span className={cx(classNames.fieldLabel, classNames.firstLabel)}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Start \n date')}
            </Typography>
          </span>
          <BorderFieldLayout
            inputComponent={DateInput}
            value={startDateInput.value}
            onChange={handleStartDateChange}
            key={selectedCardsLength}
            disabledDate={disabledDate}
            defaultPickerValue={defaultPickerValue}
            onDateChange={clearDurations}
            withoutError
            disabled={inShoppingCart}
          />
        </Col>
        <Col span={24} lg={9} className={classNames.labelField}>
          <span className={classNames.fieldLabel}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('Duration')}
            </Typography>
          </span>
          <SelectField
            name="duration"
            options={prepareOptionsToDuration(durationOptions)}
            disabled={isEmpty(durationOptions) || inShoppingCart}
            labelKey="label"
            valueKey="duration"
            withoutError
            placeholder={isEmpty(durationOptions) ? '' : gettext('Select')}
          />
        </Col>
        <Col span={24} lg={7} className={classNames.labelField}>
          <span className={classNames.fieldLabel}>
            <Typography
              variant="label"
              color="light-black"
              transform="uppercase"
              tag="span"
            >
              {gettext('End \n date')}
            </Typography>
          </span>
          <div className={cx(classNames.endDate, inShoppingCart && classNames.disabled)}>
            {changeDateFormat(currentDuration?.end_date)}
          </div>
        </Col>
      </Row>
    </Fragment>
  )
}
