import { connect } from 'react-redux'
import get from 'lodash/get'

import AddOnsView from './AddOnsView'


export default connect((state) => ({
  addOns: [
    ...get(state, 'schoolNewServices.data.private_lessons', []).map(addon => ({ ...addon, kind: 'private_lesson' })),
    ...get(state, 'schoolNewServices.data.leisure_programs', []).map(addon => ({ ...addon, kind: 'leisure_program' })),
    ...get(state, 'schoolNewServices.data.work_programs', []).map(addon => ({ ...addon, kind: 'work_program' })),
  ],
}))(AddOnsView)
