import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import get from 'lodash/get'
import isEqual from 'lodash/isEqual'
import omit from 'lodash/omit'
import { useMemo, useEffect } from 'react'

import { SelectField } from 'common/forms'
import classNames from './styles.module.scss'
import prepareOptionsToDuration from '../utils/prepareOptionsToDuration'
import { CardTextContent } from 'common/widgets'
import { useFormState } from 'react-final-form'


const kinds = {
  leisure_program: {
    options: 'options',
    name_of_field: 'option_id',
    labelKey: 'option.name',
    valueKey: 'option.id',
  },
  work_program: {
    options: 'durations',
    name_of_field: 'duration',
    labelKey: 'label',
    valueKey: 'duration',
  },
  private_lesson: {
    options: 'durations',
    name_of_field: 'duration',
    labelKey: 'label',
    valueKey: 'duration',
  },
}

AddOnCard.propTypes = {
  data: PropTypes.object.isRequired,
  kind: PropTypes.string.isRequired,
  priceBlock: PropTypes.node.isRequired,
  inShoppingCart: PropTypes.bool.isRequired,
  setPrice: PropTypes.func.isRequired,
  fieldData: PropTypes.object,
  defaultOptions: PropTypes.array,
}

AddOnCard.defaultProps = {
  fieldData: {},
  defaultOptions: [],
}

export default function AddOnCard({ data, fieldData, priceBlock, kind, inShoppingCart, setPrice, defaultOptions }) {
  const { values } = useFormState()

  const options = useMemo(_ => {
    const options = get(fieldData, `[${kinds[kind].options}]`, defaultOptions)
    return kind !== 'leisure_program' ? prepareOptionsToDuration(options) : options
  }, [fieldData, kind])

  useEffect(() => {
    if(values[kinds[kind].name_of_field]) {
      const currentValue = values[kinds[kind].name_of_field]
      const selectedOption = options.find(i => isEqual(get(i, kinds[kind].valueKey), currentValue))
      const fieldName = kind === 'private_lesson' ? 'pricePerLesson' : 'pricePerWeek'
      const durationQuantity = get(selectedOption, 'duration.quantity', 1)
      if(selectedOption) {
        setPrice({
          priceInCurrency: get(selectedOption, 'price.reference') ? get(selectedOption, 'price') : {},
          priceInChf: get(selectedOption, 'price.reference') ? get(selectedOption, 'price.reference') : omit(get(selectedOption, 'price'), ['reference']),
          [fieldName]: kind === 'private_lesson' ? {
            value: (get(selectedOption, 'price.reference.value') ? get(selectedOption, 'price.reference.value') : get(selectedOption, 'price.value')) / durationQuantity,
            currency: 'CHF',
          } : {},
        })
      }
    }
  }, [values[kinds[kind].name_of_field]])

  return (
    <div>
      <Row type="flex" align="flex-start" justify="space-between" className={classNames.contentRow}>
        <Col>
          <CardTextContent
            studentAge={{ avg: data.avg_student_age, max: data.max_student_age, min: data.min_student_age }}
            groupSize={{ avg: data.avg_students_count, max: data.max_students_count, min: data.min_students_count }}
            languageNiveau={{ min: data.language_niveau_min, max: data.language_niveau_max }}
          />
        </Col>
        <Col className={classNames.cardPrice}>
          {priceBlock}
        </Col>
      </Row>
      <div className={classNames.fieldBlock}>
        <div className={classNames.labelField}>
          <span className={classNames.typeField}>
            <i className="schedule" />
          </span>
          <SelectField
            name={kinds[kind].name_of_field}
            options={options}
            labelKey={kinds[kind].labelKey}
            valueKey={kinds[kind].valueKey}
            disabled={inShoppingCart}
            withoutError
          />
        </div>
      </div>
    </div>
  )
}
