import { useCallback, useMemo, Fragment } from 'react'
import { Row, Col } from 'antd'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { CSSTransition } from 'react-transition-group'
import Head from 'next/head'
import get from 'lodash/get'
import template from 'lodash/template'
import noop from 'lodash/noop'
import omit from 'lodash/omit'

import SchoolHero from './widgets/SchoolHero'
import SchoolDescription from './widgets/SchoolDescription'
import SchoolHighlights from './widgets/SchoolHighlights'
import SchoolNationality from './widgets/SchoolNationality'
import Amenity from './widgets/Amenity'
import Map from './widgets/Map'
import Services from './Services'
import Cart from './widgets/Cart'
import { Container, Typography, Button } from 'common/widgets'
import { ExperienceMasonry, ProductReviews } from 'common/widgets/blocks'
import classNames from './styles.module.scss'
import { useTranslations, interpolate } from 'common/language'
import parseTemplateData from 'common/utils/parseTemplateData'
import scrollSmoothTo from 'common/utils/scrollSmoothTo'
import useCart from 'hooks/useCart'
import getImgFromBynder from 'common/utils/getImgFromBynder'
import structuredDataSchema from 'common/utils/structuredDataSchema'


const cartIcon = ['fal', 'shopping-cart']

SchoolPageView.propTypes = {
  school: PropTypes.object,
  schoolNew: PropTypes.object,
  openSidebar: PropTypes.bool,
  toggleSidebar: PropTypes.func,
  moveBack: PropTypes.func.isRequired,
  seoTemplates: PropTypes.object.isRequired,
}

SchoolPageView.defaultProps = {
  openSidebar: false,
  school: {},
  schoolNew: {},
  toggleSidebar: noop,
}

export default function SchoolPageView({ schoolNew, toggleSidebar, openSidebar, seoTemplates, moveBack }) {
  const cartData = useCart()
  const cartLength = useMemo(() => Object.keys(cartData.data).length, [cartData.data])
  const { gettext, languageCode } = useTranslations()
  const seoContext = useMemo(() => ({
    'country_name': get(schoolNew, 'static_content.country.name', ''),
    'destination_name': get(schoolNew, 'static_content.destination.name', ''),
    'school_name': get(schoolNew, 'name', ''),
  }), [schoolNew])
  const title = template(parseTemplateData(get(seoTemplates['public_site.SchoolPage'], `title_${languageCode}`, ''), seoContext))(seoContext)
  const description = template(parseTemplateData(get(seoTemplates['public_site.SchoolPage'], `description_${languageCode}`, ''), seoContext))(seoContext)
  const origin = useMemo(() => typeof window === 'undefined' ? process.env.ORIGIN_URL : window.location.origin, [])
  const generateLinkPath = useCallback((data, lang) => {
    return origin + `/${lang}/${data.country_slug}/${data.destination_slug}/${data.school_slug}`
  }, [origin])
  const alternateDe = useMemo(() => get(schoolNew, 'static_content.hreflang_data.de'), [schoolNew?.static_content?.hreflang_data])
  const alternateFr = useMemo(() => get(schoolNew, 'static_content.hreflang_data.fr'), [schoolNew?.static_content?.hreflang_data])
  const isSchemaEnabled = useMemo(() => get(schoolNew, 'static_content.rating.reviews_count', 0) > 0, [schoolNew?.static_content?.rating?.reviews_count])
  const schema = useMemo(() => isSchemaEnabled && structuredDataSchema({
    url: generateLinkPath(get(schoolNew, ['static_content', 'hreflang_data', languageCode], ''), languageCode),
    name: interpolate(gettext('Language Stay %s'), [get(schoolNew, 'name', '')]),
    score: get(schoolNew, 'static_content.rating.overall', ''),
    reviewCount: get(schoolNew, 'static_content.rating.reviews_count', '') }),
  [isSchemaEnabled, schoolNew.static_content.hreflang_data, schoolNew.static_content.rating])
  return (
    <Fragment>
      <Head>
        <title>{title}</title>
        <meta name="description" content={description} key="description" />
        {schoolNew?.static_content?.hreflang_data && (
          <link rel="canonical" href={generateLinkPath(schoolNew?.static_content?.hreflang_data[languageCode], languageCode)} />
        )}
        {alternateDe && <link rel="alternate" hrefLang="de" href={generateLinkPath(alternateDe, 'de')} />}
        {alternateFr && <link rel="alternate" hrefLang="fr" href={generateLinkPath(alternateFr, 'fr')} />}
        {alternateDe && <link rel="alternate" hrefLang="x-default" href={generateLinkPath(alternateDe, 'de')} />}
        <meta property="og:title" content={title} key="og-title" />
        <meta property="og:description" content={description} key="og-description" />
        <meta property="og:image" content={getImgFromBynder(get(schoolNew, 'static_content.media_images[0].bynder', {}), ['webImage'])} key="og-image" />
        <meta property="og:url" content={generateLinkPath(schoolNew?.static_content?.hreflang_data[languageCode], languageCode)} key="og-url" />
        <meta property="og:type" content="website" key="og-type" />
        {isSchemaEnabled && (
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{ __html: schema }}
          />
        )}
      </Head>
      <SchoolHero languageCode={languageCode} />
      <SchoolDescription />
      <Amenity />
      <Container className={classNames.HighWrapper} size="xl">
        <Row>
          <Col
            span={24}
            lg={13}
          >
            <SchoolHighlights />
          </Col>
          <Col span={0} lg={1} />
          <Col
            span={24}
            lg={9}
          >
            <SchoolNationality />
          </Col>
        </Row>
      </Container>
      <Map className={classNames.pageMap} />
      <Container size="xl" className={classNames.servicesTitleWrapper}>
        <Typography variant="h2">
          {schoolNew.name}
          {gettext(' - Courses & Accommodation')}
        </Typography>
      </Container>
      <div className={classNames.CartWrapper} id="prices">
        <Container className={classNames.CartContainer} size="xl">
          <Row gutter={{ xs: 0, lg: 20 }}>
            <CSSTransition
              in={openSidebar}
              timeout={500}
              classNames="transition-cart"
            >
              <Col
                span={0}
                lg={7}
                className={cx(classNames.cart)}
              >
                <Cart toggleCart={toggleSidebar} withSchoolMock withLink />
              </Col>
            </CSSTransition>
            <Col
              span={24}
              lg={17}
            >
              <Services />
            </Col>
          </Row>
        </Container>
        <div className={classNames.triggerWrapper}>
          <Button
            icon={cartIcon}
            iconBadge={cartLength > 0 ? cartLength : undefined}
            className={cx(classNames.sideNav, classNames.desktop)}
            onClick={_ => scrollSmoothTo('prices')}
          >
            <span className={classNames.buttonText}>
              {gettext('Prices')}
            </span>
          </Button>
          <Button
            icon={cartIcon}
            iconBadge={cartLength > 0 ? cartLength : undefined}
            className={cx(classNames.sideNav, classNames.mobile)}
            onClick={toggleSidebar}
          >
            <span className={classNames.buttonText}>
              {gettext('Prices')}
            </span>
          </Button>
          <Button
            icon="comment"
            className={cx(classNames.sideNav)}
            onClick={_ => scrollSmoothTo('reviews')}
          >
            <span className={classNames.buttonText}>
              {gettext('Reviews')}
            </span>
          </Button>
          <Button
            icon="chevron-up"
            className={cx(classNames.sideNav)}
            onClick={_ => scrollSmoothTo('__next')}
          >
            <span className={classNames.buttonText}>
              {gettext('Top')}
            </span>
          </Button>
          <Button
            icon="chevron-left"
            className={cx(classNames.sideNav)}
            onClick={moveBack}
          >
            <span className={classNames.buttonText}>
              {gettext('Back')}
            </span>
          </Button>
        </div>
      </div>
      <Container size="xl" id="reviews">
        <Typography variant="h2" >
          {interpolate(gettext('%s Customer Reviews'), [schoolNew.name])}
        </Typography>
      </Container>
      <ProductReviews
        data={{
          kind: 'school',
          parent_id: schoolNew?.id,
          rating: schoolNew.static_content.rating.overall,
          reviews_count: schoolNew.static_content.rating.reviews_count,
          scores: omit(schoolNew.static_content.rating, ['reviews_count', 'overall']),
        }}
      />
      <Container size="xl">
        <Typography variant="h2" className={classNames.snapshotsTitle} >
          {interpolate(gettext('%s Snapshots'), [schoolNew.name])}
        </Typography>
      </Container>
      <ExperienceMasonry
        data={{ kind: 'school', parent_id: schoolNew?.id }}
        withoutCTA
      />
    </Fragment>
  )
}
