import { connect } from 'react-redux'
import get from 'lodash/get'

import AccommodationsView from './AccommodationsView'


export default connect((state) => ({
  accommodations: get(state, 'schoolNewServices.data.accommodations', []),
  destination: get(state, 'schoolNewServices.data.static_content.destination', {}),
}))(AccommodationsView)
