import { useState, useEffect } from 'react'
import cx from 'classnames'
import PropTypes from 'prop-types'

import { MapMultipleMarkers } from 'common/widgets'
import classNames from './styles.module.scss'


MapView.propTypes = {
  className: PropTypes.string,
}

MapView.defaultProps = {
  className: undefined,
}

export default function MapView({ className, ...props }) {
  const [map, setMap] = useState(null)
  useEffect(() => {
    setTimeout(() => {
      setMap(<MapMultipleMarkers {...props} />)
    }, 500)
  }, [])
  return (
    <div className={cx(classNames.singlePageMap, className)}>
      {map}
    </div>
  )
}
