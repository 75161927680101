import { useMemo, useCallback } from 'react'
import { Form } from 'react-final-form'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import get from 'lodash/get'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'

import { setCartExpiredDate } from 'common/utils/cartDate'
import useCart from 'hooks/useCart'
import API from 'api'
import CourseCardWrapperContainer from './CourseCardWrapperContainer'


CourseCardWrapper.propTypes = {
  initialValues: PropTypes.object,
  hrefLangData: PropTypes.object,
  id: PropTypes.string.isRequired,
}

CourseCardWrapper.defaultProps = {
  initialValues: {},
  hrefLangData: {},
  currentCart: null,
}

function CourseCardWrapper({ initialValues, id, hrefLangData, ...props }) {
  const { locale, query, push, pathname } = useRouter()
  const cartData = useCart()

  const currentCart = useMemo(() => cartData.data[query.option], [cartData.data, query.option])
  const cartInitialValues = useMemo(() => {
    const item = currentCart && currentCart.course_cards.find(i => i.course_id === id)
    return item ? pick(item, ['course_id', 'intensity_id', 'start_date', 'duration', 'price', 'promotions']) : null
  }, [currentCart, id])

  const putToCartHandler = useCallback(data => {
    if(cartInitialValues) {
      setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
      cartData.setData(omit(cartData.data, [query.option]))
      return push({
        pathname: pathname,
        query: {
          levels: query.levels,
        },
      }, undefined, { shallow: true, scroll: false })
    }
    cartData.setCartLoading(true)
    API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/options`,
      {
        ...(currentCart || {}),
        course_cards: [
          ...(currentCart?.course_cards || []),
          { ...data, course_id: id },
        ],
      },
      { headers: { 'Accept-Language': locale } }
    )
      .then(resp => {
        const data = {
          ...cartData.data,
          [query.option || resp.id]: {
            ...resp,
            locale,
            hrefLangData,
          },
        }
        setCartExpiredDate(process.env.CART_EXPIRATION_TIME)
        cartData.setData(data)
        !query.option && push({
          pathname: pathname,
          query: {
            ...query,
            option: resp.id,
          },
        }, undefined, { shallow: true, scroll: false })
      })
      .finally(() => cartData.setCartLoading(false))
  }, [currentCart, query.option, cartData.setCartLoading, cartData.data, id])

  return (
    <Form
      onSubmit={putToCartHandler}
      initialValues={cartInitialValues || initialValues || {}}
    >
      {(formProps) => (
        <CourseCardWrapperContainer
          {...formProps}
          {...props}
          id={id}
          cartInitialValues={cartInitialValues}
          initialValues={initialValues}
          currentCart={currentCart}
          isCartLoading={cartData.isCartLoading}
          defaultIntensity={initialValues?.intensity_id}
        />
      )}
    </Form>
  )
}

export default connect(state => ({ hrefLangData: get(state, 'schoolNewServices.data.static_content.hreflang_data', {}) }))(CourseCardWrapper)
