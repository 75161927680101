import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import cx from 'classnames'
import { Fragment, useMemo } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import isEmpty from 'lodash/isEmpty'

import { Typography, Info } from 'common/widgets'
import SelectButton from './SelectButton'
import PromotionLabel from './PromotionLabel'
import ModalTrigger from 'common/modals/ModalTrigger'
import { useTranslations } from 'common/language'
import Map from '../../widgets/Map'
import classNames from './styles.module.scss'


CardTitle.propTypes = {
  title: PropTypes.string.isRequired,
  topTitle: PropTypes.string,
  description: PropTypes.node,
  location: PropTypes.object,
  promotions: PropTypes.array,
}

CardTitle.defaultProps = {
  description: undefined,
  location: {},
  topTitle: '',
  promotions: [],
}

const tooltipOffset = { offset: ['30%'] }
const boundsPadding = { top: 130, left: 70, right: 70, bottom: 20 }

export default function CardTitle({ title, topTitle, description, location, promotions, ...rest }) {
  const { gettext } = useTranslations()
  const additionalLocations = useMemo(() => [{
    name_first_line: topTitle,
    name_second_line: title,
    rating: '',
    tooltipContent: '<h2><%= name_first_line %></h2><h3><%= name_second_line %></h3>',
    ...location,
  }], [topTitle, title, location])
  return (
    <Row type="flex" justify="space-between" align="top" className={classNames.cardTitle}>
      <Col flex="auto" className={classNames.cardName}>
        <Typography
          className={cx(classNames.text, classNames.cardTitleText)}
          variant="h4-card"
        >
          {topTitle && (
            <Fragment>
              <span>{topTitle + ' '}</span>
              <span className={classNames.desktopSlash}>/</span>
            </Fragment>
          )}
          <span>
            {title}
            {description && <Info title={description} align={tooltipOffset} overlayClassName={classNames.cardTooltip} />}
          </span>
        </Typography>
        <div className={classNames.tagsContainer}>
          {!isEmpty(location) && (
            <div>
              <ModalTrigger
                component={Map}
                width={1280}
                additionalLocations={additionalLocations}
                modalClassName={classNames.modalWrapper}
                title="Modal"
                boundsPadding={boundsPadding}
                preventHover
              >
                <button className={classNames.showOnMap}>
                  <FontAwesomeIcon icon="map-marker-alt" />
                  <span>{gettext('Show on map')}</span>
                </button>
              </ModalTrigger>
            </div>
          )}
          {promotions.map((promo, idx) => <PromotionLabel key={idx} title={promo?.description} />)}
        </div>
      </Col>
      <Col className={classNames.buttonCol}>
        <SelectButton {...rest} />
      </Col>
    </Row>
  )
}
