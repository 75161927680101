import { Component } from 'react'
import isEmpty from 'lodash/isEmpty'
import PropTypes from 'prop-types'
import { ResourceType } from '@ds-frontend/resource'

import ServicesView from './ServicesView'


const propTypes = {
  cartDataSingle: PropTypes.object.isRequired,
  cartData: ResourceType.isRequired,
  values: PropTypes.object,
  router: PropTypes.object,
}

const defaultProps = {
  values: {},
  router: {},
}

export default class ServicesContainer extends Component {
  componentDidUpdate(prevProps) {
    if(!isEmpty(prevProps.cartDataSingle) && isEmpty(this.props.cartDataSingle)) {
      this.props.router.push({
        pathname: this.props.router.pathname,
        query: {
          levels: this.props.router.query.levels,
        },
      }, undefined, { shallow: true, scroll: false })
    }
  }

  render() {
    return (
      <ServicesView {...this.props} />
    )
  }
}

ServicesContainer.propTypes = propTypes
ServicesContainer.defaultProps = defaultProps
