import get from 'lodash/get'
import pick from 'lodash/pick'
import omit from 'lodash/omit'
import isEmpty from 'lodash/isEmpty'
import orderBy from 'lodash/orderBy'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState, useMemo } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import API from 'api'
import CardWrapperView from './CardWrapperView'
import usePrevious from 'common/utils/usePrevious'
import getCardPricePromotion from 'common/utils/getCardPricePromotion'


AccommodationCardWrapperContainer.propTypes = {
  id: PropTypes.string.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  cartInitialValues: PropTypes.object,
  initialValues: PropTypes.object,
  currentCart: PropTypes.object,
  defaultPrice: PropTypes.object,
}

AccommodationCardWrapperContainer.defaultProps = {
  cartInitialValues: {},
  initialValues: {},
  currentCart: {},
  defaultPrice: {},
}

export default function AccommodationCardWrapperContainer({ id, cartInitialValues, initialValues, currentCart, isCartLoading, defaultPrice, ...props }) {
  const { locale, query } = useRouter()
  const [fieldData, setFieldData] = useState({})
  const [price, setPrice] = useState({
    minPriceInCurrency: {},
    priceInCurrency: {},
    minPriceInChf: {},
    priceInChf: {},
    ...defaultPrice,
    calculatedPromotion: null,
  })
  const [isLoading, setLoading] = useState(false)
  const form = useForm()

  const accommodation = useMemo(_ => get(currentCart, 'accommodation_cards', []).find(i => i.accommodation_id === id), [currentCart?.accommodation_cards, id])

  const appliedPromotionTag = useMemo(_ => get(accommodation, 'promotions', []).find(i => i.promotion.display_promo_tag)?.promotion ?? null, [accommodation])

  const handleSetPrice = useCallback((data = {}) => {
    setPrice(prevPrice => ({
      ...prevPrice,
      ...data,
    }))
  }, [])

  const handleRequest = useCallback((data) => {
    setLoading(true)
    return API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/accommodation/${id}`,
      { ...data },
      { headers: { 'Accept-Language': locale } },
    )
      .then(resp => {
        setFieldData(resp)
        return resp
      })
      .finally(() => setLoading(false))
  }, [locale, id, setFieldData])

  useEffect(() => {
    if(cartInitialValues) {
      form.initialize(pick({ ...cartInitialValues }, ['accommodation_id', 'combination_id', 'arrival_date', 'duration']))
    } else {
      form.initialize(initialValues)
    }
  }, [Boolean(cartInitialValues)])

  const prevCart = usePrevious(currentCart)

  useEffect(() => {
    if((prevCart?.course_cards?.length !== currentCart?.course_cards?.length || prevCart?.accommodation_cards?.length !== currentCart?.accommodation_cards?.length) && !cartInitialValues) {
      if(currentCart?.course_cards?.length > 0) {
        handleRequest(pick({ ...currentCart }, ['course_cards', 'accommodation_cards']))
          .then(resp => {
            const combinationWithLongestDuration = orderBy(get(resp, 'combinations', []).map(i => ({
              combination: i,
              last_duration_quantity: get(i, 'arrival_dates[0].durations', [])?.at(-1)?.duration?.quantity,
            })), ['last_duration_quantity'], ['desc'])?.[0]?.combination
            const defaultArrivalDate = combinationWithLongestDuration?.default_arrival_date
            const combination = combinationWithLongestDuration?.combination
            const arrivalDates = get(combinationWithLongestDuration, 'arrival_dates', [])
            const firstDate = arrivalDates.find(i => i.arrival_date === defaultArrivalDate) || arrivalDates[0]
            const lastDurations = firstDate?.durations
            const lastDuration = lastDurations?.[lastDurations.length - 1]
            const calculatedPromotion = getCardPricePromotion(lastDuration?.promotions)
            if(calculatedPromotion) {
              calculatedPromotion.duration = get(lastDuration, 'duration.quantity')
            }
            handleSetPrice({
              priceInCurrency: get(lastDuration, 'price.reference') ? get(lastDuration, 'price', {}) : {},
              priceInChf: get(lastDuration, 'price.reference') ? get(lastDuration, 'price.reference', {}) : omit(get(lastDuration, 'price', {}), ['reference']),
              pricePerWeek: lastDuration ? {
                currency: get(lastDuration, 'price.reference.currency', get(lastDuration, 'price.currency')),
                value: get(lastDuration, 'price.reference.value', get(lastDuration, 'price.value')) / lastDuration.duration.quantity,
              } : {},
              minPriceInCurrency: get(combination, 'min_price.reference') ? get(combination, 'min_price') : {},
              minPriceInChf: get(combination, 'min_price.reference') ? get(combination, 'min_price.reference') : omit(get(combination, 'min_price'), ['reference']),
              calculatedPromotion: calculatedPromotion,
            })
            if(lastDuration) {
              form.initialize({
                accommodation_id: resp?.accommodation,
                combination_id: combination?.id,
                arrival_date: firstDate.arrival_date,
                duration: lastDuration.duration,
              })
            } else {
              handleSetPrice({
                priceInCurrency: {},
                priceInChf: {},
                pricePerWeek: {},
                calculatedPromotion: null,
                ...defaultPrice,
              })
              setFieldData({})
              form.initialize(initialValues)
            }
          })
      } else {
        handleSetPrice({
          priceInCurrency: {},
          priceInChf: {},
          pricePerWeek: {},
          calculatedPromotion: null,
          ...defaultPrice,
        })
        setFieldData({})
        form.initialize(initialValues)
      }
    }
  }, [currentCart?.course_cards?.length, currentCart?.accommodation_cards?.length])

  const prevOption = usePrevious(query.option)

  useEffect(() => {
    const generatePriceAfterRequest = resp => {
      if(!isEmpty(cartInitialValues)) {
        const combination = get(resp, 'combinations', []).find(i => i.combination.id === cartInitialValues.combination_id)
        const arrivalDate = combination?.arrival_dates?.find(i => i.arrival_date === cartInitialValues.arrival_date)
        const duration = arrivalDate?.durations?.find(i => i.duration.quantity === cartInitialValues.duration.quantity)
        const calculatedPromotion = !isEmpty(duration?.promotions) ? getCardPricePromotion(duration?.promotions) : getCardPricePromotion(accommodation?.promotions)
        if(calculatedPromotion) {
          calculatedPromotion.duration = get(duration, 'duration.quantity')
        }
        handleSetPrice({
          priceInCurrency: get(duration, 'price.reference') ? get(duration, 'price', {}) : {},
          priceInChf: get(duration, 'price.reference') ? get(duration, 'price.reference', {}) : omit(get(duration, 'price', {}), ['reference']),
          pricePerWeek: duration ? {
            currency: get(duration, 'price.reference.currency', get(duration, 'price.currency')),
            value: get(duration, 'price.reference.value', get(duration, 'price.value')) / duration.duration.quantity,
          } : {},
          calculatedPromotion: calculatedPromotion,
        })
      }
      return resp
    }
    if(!prevOption && query.option && !isEmpty(cartInitialValues)) {
      handleRequest(pick({ ...currentCart }, ['course_cards']))
        .then(generatePriceAfterRequest)
    }

    if(prevOption && query.option && prevOption !== query.option && currentCart) {
      handleRequest(!isEmpty(cartInitialValues) ? pick({ ...currentCart }, ['course_cards']) : pick({ ...currentCart }, ['course_cards', 'accommodation_cards']))
        .then(generatePriceAfterRequest)
    }
  }, [query.option])

  const prevCartLocale = usePrevious(currentCart?.locale)

  useEffect(() => {
    if(prevCartLocale && currentCart?.locale && prevCartLocale !== currentCart?.locale && cartInitialValues) {
      form.initialize(cartInitialValues)
    }
  }, [currentCart?.locale])

  useEffect(() => {
    const calculatedPromotion = getCardPricePromotion(accommodation?.promotions)
    const prevPromotion = getCardPricePromotion((prevCart, 'accommodation_cards', []).find(i => i.accommodation_id === id)?.promotions)
    if(calculatedPromotion) {
      calculatedPromotion.duration = get(accommodation, 'duration.quantity')
    }
    if(calculatedPromotion && calculatedPromotion?.promotion?.id !== prevPromotion?.promotion?.id) {
      handleSetPrice({ calculatedPromotion: calculatedPromotion })
    } else if(prevPromotion && calculatedPromotion?.promotion?.id !== prevPromotion?.promotion?.id) {
      handleSetPrice({ calculatedPromotion: null })
    }
  }, [accommodation?.promotions])

  return (
    <CardWrapperView
      {...props}
      fieldData={fieldData}
      price={price}
      appliedPromotionTag={appliedPromotionTag}
      setPrice={handleSetPrice}
      inShoppingCart={Boolean(cartInitialValues)}
      isLoading={isLoading || isCartLoading}
    />
  )
}
