import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { useRouter } from 'next/router'
import get from 'lodash/get'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import moment from 'moment'

import { Typography } from 'common/widgets'
import AccommodationCard from '../widgets/AccommodationCard'
import CardWrapper from '../widgets/AccommodationCardWrapper'
import classNames from '../styles.module.scss'
import { useTranslations, interpolate } from 'common/language'
import airBnb from 'img/airbnb.jpeg'
import SimpleCardView from '../widgets/SimpleCardView'


AccommodationsView.propTypes = {
  accommodations: PropTypes.array.isRequired,
  destination: PropTypes.object.isRequired,
}

export default function AccommodationsView({ accommodations, destination, ...rest }) {
  const { gettext } = useTranslations()
  const { locale } = useRouter()
  const currentMonth = moment().locale('en').format('MMMM')?.toLowerCase()
  const nextMonth = moment().add(1, 'month').locale('en').format('MMMM')?.toLowerCase()
  const airBnbUrl = useMemo(() => {
    const domain = locale === 'de' ? 'www.airbnb.ch' : 'fr.airbnb.ch'
    return interpolate(
      `https://%s/s/%s/homes?tab_id=home_tab&refinement_paths[]=%2Fhomes&flexible_trip_dates[]=${nextMonth}&flexible_trip_dates[]=${currentMonth}&flexible_trip_lengths[]=weekend_trip&date_picker_type=calendar&source=structured_search_input_header&search_type=search_query`,
      [domain, destination.slug]
    )
  }, [locale, destination.slug])
  return (
    <div className={classNames.serviceList}>
      <div className={classNames.serviceTitleWrapper}>
        <Typography className={classNames.serviceTitle} variant="h3-title" transform="uppercase">{gettext('Accommodations')}</Typography>
      </div>
      {accommodations.map((service) => (
        <CardWrapper
          key={service.id}
          id={service.id}
          data={service.static_content}
          kind="accommodation"
          title={service.name}
          promotions={service?.promotions}
          initialValues={{ combination_id: orderBy(service?.combinations, 'min_price.value')[0].combination?.id }}
          defaultPrice={{
            minPriceInCurrency: get(service, 'min_price.reference') ? get(service, 'min_price') : {},
            minPriceInChf: get(service, 'min_price.reference') ? get(service, 'min_price.reference') : omit(get(service, 'min_price'), ['reference']),
          }}
          defaultDenialMessage={service.denial_message}
          {...rest}
        >
          {(props) => (
            <AccommodationCard
              data={service.static_content}
              defaultCombinations={service.combinations}
              {...props}
            />
          )}
        </CardWrapper>
      ))}
      <SimpleCardView
        imgSrc={airBnb}
        title={gettext('Unterkunft selbständig buchen')}
        tooltip={gettext('Keine passende Unterkunft gefunden? Neben den Angeboten der Sprachschule lohnt sich auch ein Blick auf Alternativen. Viele Kunden buchen ihre Unterkunft selbständig, zum Beispiel auf Airbnb.')}
        description={gettext('Destinationen auf authentische Weise erleben. Privatzimmer, Hotelzimmer oder ganze Unterkünfte. Auf Airbnb findest du über 4 Millionen Gastgeber. \n Vielleicht auch die passende Unterkunft in deiner Sprachreise Destination.')}
        linkText={interpolate(gettext('Airbnb Unterkünfte für %s ansehen'), [destination.name])}
        linkUrl={airBnbUrl}
        imgWrapperClassName={classNames.airBnb}
      />
    </div>
  )
}
