import get from 'lodash/get'


export default function makeOrderByKind(data, slugs, key = 'uuid') {
  return slugs.reduce((sum, val) => {
    const value = data.find(i => get(i, key) === val)
    if(value) {
      return [...sum, value]
    }
    return sum
  }, [])
}
