import get from 'lodash/get'
import pick from 'lodash/pick'
import isEmpty from 'lodash/isEmpty'
import { useRouter } from 'next/router'
import { useCallback, useEffect, useState } from 'react'
import { useForm } from 'react-final-form'
import PropTypes from 'prop-types'

import API from 'api'
import CardWrapperView from './CardWrapperView'
import usePrevious from 'common/utils/usePrevious'


AddonCardWrapperContainer.propTypes = {
  id: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  isCartLoading: PropTypes.bool.isRequired,
  cartInitialValues: PropTypes.object,
  initialValues: PropTypes.object,
  currentCart: PropTypes.object,
}

AddonCardWrapperContainer.defaultProps = {
  cartInitialValues: {},
  initialValues: {},
  currentCart: {},
}

export default function AddonCardWrapperContainer({ id, cartInitialValues, initialValues, currentCart, isCartLoading, kind, ...props }) {
  const { locale, query } = useRouter()
  const [fieldData, setFieldData] = useState({})
  const [price, setPrice] = useState({
    minPriceInCurrency: {},
    priceInCurrency: {},
    minPriceInChf: {},
    priceInChf: {},
  })
  const [ isLoading, setLoading ] = useState(false)
  const form = useForm()

  const handleSetPrice = useCallback((data = {}) => {
    setPrice({
      ...price,
      ...data,
    })
  }, [price, setPrice])

  const handleRequest = useCallback((data) => {
    setLoading(true)
    return API.post(
      `api/v2/public/schools/${get(query, 'levels[2]')}/${kind.replace('_', '-')}/${id}`,
      { ...data },
      { headers: { 'Accept-Language': locale } },
    )
      .then(resp => {
        setFieldData(resp)
        return resp
      })
      .finally(() => setLoading(false))
  }, [locale, id, setFieldData, handleSetPrice, currentCart])

  const prevCart = usePrevious(currentCart)

  useEffect(() => {
    if(prevCart?.course_cards?.length !== currentCart?.course_cards?.length && !cartInitialValues) {
      if(currentCart?.course_cards?.length > 0) {
        handleRequest(pick({ ...currentCart }, ['course_cards', `${kind}_cards`]))
          .then(resp => {
            const formData = {
              'private_lesson': {
                duration: get(resp, 'durations[0].duration'),
              },
              'work_program': {
                duration: get(resp, 'durations[0].duration'),
              },
              'leisure_program': {
                option_id: get(resp, 'options[0].option.id'),
              },
            }
            form.initialize(formData[kind])
          })
      } else {
        setFieldData({})
      }
    }
  }, [currentCart?.course_cards?.length])

  useEffect(() => {
    if(query.option && !isEmpty(cartInitialValues)) {
      handleRequest(pick({ ...currentCart }, ['course_cards']))
    }
  }, [query.option])

  useEffect(() => {
    if(cartInitialValues) {
      form.initialize({ ...cartInitialValues, kind })
    } else {
      form.initialize(initialValues)
    }
  }, [Boolean(cartInitialValues)])

  const prevCartLocale = usePrevious(currentCart?.locale)

  useEffect(() => {
    if(prevCartLocale && currentCart?.locale && prevCartLocale !== currentCart?.locale && cartInitialValues) {
      form.initialize(cartInitialValues)
    }
  }, [currentCart?.locale])

  return (
    <CardWrapperView
      {...props}
      fieldData={fieldData}
      price={price}
      setPrice={handleSetPrice}
      inShoppingCart={Boolean(cartInitialValues)}
      isLoading={isLoading || isCartLoading}
      kind={kind}
    />
  )
}
