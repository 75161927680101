import PropTypes from 'prop-types'
import { useMemo } from 'react'

import { Typography } from 'common/widgets'
import classNames from './styles.module.scss'


GenerateTooltipDescription.propTypes = {
  tooltip: PropTypes.object,
}

GenerateTooltipDescription.defaultProps = {
  tooltip: {
    category_description: undefined,
    type_description: undefined,
    specific_description: undefined,
  },
}

export default function GenerateTooltipDescription({ tooltip }) {
  const { category_description: categoryDescription, type_description: typeDescription, specific_description: specificDescription } = tooltip
  const tooltipContent = useMemo(() => [categoryDescription, typeDescription, specificDescription].filter(i => i), [categoryDescription, typeDescription, specificDescription])
  const content = useMemo(() => tooltipContent.map(i => <div className={classNames.tooltipDescription} key={i}>{i}</div>), [tooltipContent])
  return (
    <Typography variant="tooltip" color="black">
      {content}
    </Typography>
  )
}
