import PropTypes from 'prop-types'
import { Row, Col } from 'antd'
import cx from 'classnames'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import get from 'lodash/get'
import uniqBy from 'lodash/uniqBy'
import { useMemo } from 'react'
import { useRouter } from 'next/router'

import CardTitle from './CardTitle'
import CardPrice from './CardPrice'
import classNames from './styles.module.scss'
import { CardSlider, Loading } from 'common/widgets'
import TooltipDescription from './TooltipDescription'
import SelectButton from './SelectButton'
import useCart from 'hooks/useCart'
import { useTranslations } from 'common/language'


CardWrapperView.propTypes = {
  children: PropTypes.func.isRequired,
  setPrice: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  price: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  inShoppingCart: PropTypes.bool,
  kind: PropTypes.string,
  defaultDenialMessage: PropTypes.string,
  fieldData: PropTypes.object,
  promotions: PropTypes.array,
  appliedPromotionTag: PropTypes.object,
}

CardWrapperView.defaultProps = {
  isLoading: false,
  inShoppingCart: false,
  putToCartHandler: noop,
  kind: 'course',
  defaultDenialMessage: undefined,
  fieldData: {},
  promotions: [],
  appliedPromotionTag: undefined,
}

export default function CardWrapperView({ children, isLoading, inShoppingCart, data, kind, title, fieldData, price, setPrice, handleSubmit, defaultDenialMessage, promotions, appliedPromotionTag }) {
  const cartData = useCart()
  const { gettext, languageCode } = useTranslations()
  const { query } = useRouter()

  const mergedPromotions = useMemo(() => appliedPromotionTag
    ? uniqBy([...promotions, appliedPromotionTag], 'id')
    : promotions,
  [promotions, appliedPromotionTag])
  const images = data.media_images.map(img => {
    img.data.alttext = get(img, `data.alttext_${languageCode}`)
    img.data.name = get(img, `data.name_${languageCode}`)
    return img
  })
  const cart = useMemo(() => get(cartData.data, query.option, {}), [query.option, cartData.data])
  const priceBlock = <CardPrice {...price} />
  const splittedTitle = title.split('/')
  const formattedTitle = useMemo(() => splittedTitle.length > 1
    ? { topTitle: splittedTitle[0], title: splittedTitle[1] }
    : { title: splittedTitle[0] }
  , [splittedTitle])
  const isCartOverfilled = useMemo(_ => isEmpty(cart) && Object.keys(get(cartData, 'data', {})).length >= 3, [cart, cartData.data])
  const denialMessage = useMemo(_ => get(fieldData, 'denial_message', isEmpty(cart) ? defaultDenialMessage : ''), [fieldData.denial_message, isEmpty(cart), defaultDenialMessage])
  const tooltipMessage = isCartOverfilled
    ? gettext('You reached the limit of 3 options in your shopping cart. Please click on get quote or delete one option to continue.')
    : denialMessage
  return (
    <Row className={cx(classNames.cardWrapper, isLoading && classNames.loadingWrapper)}>
      <Loading isLoading={isLoading} className={classNames.loading} />
      <Col span={24} lg={7}>
        <CardSlider images={images} className={classNames.slider} />
      </Col>
      <Col span={24} lg={17} className={classNames.cardBody}>
        <CardTitle
          {...formattedTitle}
          location={data.location}
          description={<TooltipDescription tooltip={data.tooltip} />}
          value={inShoppingCart}
          putToCartHandler={handleSubmit}
          disabled={isLoading || !fieldData.is_selectable || isCartOverfilled}
          denialMessage={tooltipMessage}
          kind={kind}
          prices={price}
          promotions={mergedPromotions}
        />
        {children({ priceBlock, cart, fieldData, setPrice, inShoppingCart })}
        <div className={classNames.cardMobileFooter}>
          {priceBlock}
          <SelectButton
            kind={kind}
            value={inShoppingCart}
            putToCartHandler={handleSubmit}
            disabled={isLoading || !fieldData.is_selectable || isCartOverfilled}
            denialMessage={tooltipMessage}
          />
        </div>
      </Col>
    </Row>
  )
}
