import { useFormState } from 'react-final-form'
import PropTypes from 'prop-types'
import { useMemo } from 'react'
import { Tooltip } from 'antd'
import noop from 'lodash/noop'

import { Button } from 'common/widgets'
import classNames from './styles.module.scss'
import tooltipClassNames from 'common/widgets/Info/styles.module.scss'
import { useTranslations } from 'common/language'


SelectButton.propTypes = {
  putToCartHandler: PropTypes.func.isRequired,
  value: PropTypes.bool,
  kind: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
  denialMessage: PropTypes.string,
}

SelectButton.defaultProps = {
  value: false,
  disabled: false,
  denialMessage: '',
}

const services = {
  course: ['start_date', 'duration', 'intensity_id'],
  accommodation: ['arrival_date', 'duration', 'combination_id'],
  transfer: ['combination_id'],
  leisure_program: ['option_id'],
  work_program: ['duration'],
  private_lesson: ['duration'],
}

export default function SelectButton({ value, putToCartHandler, kind, disabled, denialMessage, ...rest }) {
  const { gettext } = useTranslations()
  const { values } = useFormState()
  const disabledByKind = useMemo(_ => {
    return services[kind].some(field => !values[field])
  }, [values])
  const isDisabled = disabledByKind || disabled
  const button = (
    <Button
      {...rest}
      type={value ? 'secondary' : 'primary'}
      className={classNames.buttonSelect}
      onClick={!isDisabled ? putToCartHandler : noop}
      disabled={isDisabled}
    >
      {value ? gettext('Unselect') : gettext('Select')}
    </Button>
  )
  if(denialMessage && isDisabled) {
    return (
      <Tooltip title={denialMessage} overlayClassName={tooltipClassNames.tooltip}>
        <div className={classNames.tooltipWrapper}>
          {button}
        </div>
      </Tooltip>
    )
  }
  return button
}
